import { Link } from "react-router-dom";
import {urlEncode} from "../utils/Generic";

const ProductLink = (props) => {
  var name= urlEncode(props.obj.product_name);
  return (
    <>
    <Link to={`/product/${props.obj._id}/${name}`}>
    {props?.with_image &&
    <>
      {props?.out_of_stock &&
      <>
        <div className="mask text-light d-flex justify-content-center flex-column text-center" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
          <h4>Out of stock</h4>
        </div>
        <img style={{filter: "blur(1px)"}} src={process.env.REACT_APP_DB_URL +"/uploads/"+props.obj.images[0]} className="img-fluid" alt="" />
      </>
      }
      {!props?.out_of_stock &&
      <img src={process.env.REACT_APP_DB_URL +"/uploads/"+props.obj.images[0]} className="img-fluid" alt="" />
      }
    </>
    }
    {props?.with_name &&
      <h5 className="name " >{props.obj.product_name}</h5>
    }
    </Link>
    </>
  )
};
export default ProductLink;
