function mapStateToProps(state) {
  console.log('mapStateToProps', state);
  return {
    count: state.count
  }
};

// Note: ES6 version of a function to dispatch to props.
const mapDispatchToProps = (dispatch) => {
  return {

    setCartCount: (cnt) => {
      const action = {
        type: 'SET',
        count: cnt
      };
      // Actions must be "dispatched", as shown below.
      dispatch(action);
    },
    // This is the method used to increment the number.
    onIncrementClick: () => {
      console.log('Increment click');
      // Note: In Redux, actions are objects that have a property called "type".
      // They can have other properties as well.  Below is an action.
      const action = {
        type: 'INCREMENT'
      };
      // Actions must be "dispatched", as shown below.
      dispatch(action);
    },

    // This is the method used to decrement the number.
    onDecrementClick: () => {
      console.log('Decrement click');
      const action = {
        type: 'DECREMENT'
      };
      dispatch(action);
    },

    // This is the method used to decrement the number.
    onResetClick: () => {
      console.log('Reset click');
      const action = {
        type: 'RESET'
      };
      dispatch(action);
    }

  }
};
export {mapStateToProps, mapDispatchToProps};
