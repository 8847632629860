import Ordersummery from "../order-tracking/Ordersummery";
import { useNavigate,Link } from "react-router-dom";
import CheckOut from "../checkout/CheckOut";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Order from "../product/Order";
import Address from "../product/Address";
import {getCartList,getProductsPrice,updateCart,removeCart,applyCoupon} from "../utils/DatabaseAccess.js";
import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/Redux';
import {scrollToTop} from "../utils/Generic";
import Parser from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';

const Cart = (props) => {
  const couponInputRef = useRef(); 
  const [lists, setLists] = useState([]);
  const [counts, setCounts] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [paymentNeeded, setPaymentNeeded] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [deliveryDiscount, setDeliveryDiscount] = useState(0);
  const [deliveryMsg, setDeliveryMsg] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState();
  const [couponSuccess, setCouponSuccess] = useState();
  const [cartCount, setCartCount] = useState();
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("useriid") || null);
  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
    getHomeData();
  }, []);

  const _update = async (text, cartId, productId,prdQty,display) => {
    if (display == false){
			toast.error("Not allowed");
      return;
    }
    if (prdQty < text){
			toast.error("Stock over");
      return;
    }
    let newCounts = counts;
    newCounts[cartId] = text;

    setCounts(newCounts);
    const data = {
        userId,
        cartId,
        productId,
        qty : text
    }
    let totalCartCnt = 0;
    for (var key in counts) {
      if (counts[key] != 0)
        totalCartCnt++;
    }
    if (text == 0) {
      await removeCart(data);
      console.log(data);
    } else {
      await updateCart(data);
    }
    props.setCartCount(totalCartCnt);
    setCartCount(totalCartCnt);
    await calcTotalPrice();
  };

  const couponClick = async (state) => {
    const coupon_code = couponInputRef?.current?.value || couponCode;
    const data = {userId,coupon_code,state};
    const resData = await applyCoupon(data);
    setCouponError(""); 
    setCouponSuccess(""); 
    setCouponCode(""); 
    if (resData.data.coupon_error_msg != "") {
        setCouponError(resData.data.coupon_error_msg); 
    } else {
        settingPriceObjs(resData.data);
    }
  };

  const settingPriceObjs = (resData) => {
    setTotalPrice(resData.total_price);
    setTotalDiscount(resData.total_discount);
    setActualPrice(resData.actual_price);
    setCouponDiscount(resData.coupon_discount);
    setDeliveryCharges(resData.delivery_charge);
    setDeliveryDiscount(resData.delivery_discount);
    setDeliveryMsg(resData.delivery_msg);
    setPaymentNeeded(resData.payment_needed);
    setCouponCode(resData.coupon_code);
    setIsCouponApplied(resData.is_coupon_applied);
    setCouponError(resData.coupon_error_msg);
    setCouponSuccess(resData.coupon_success_msg);
  };

  const calcTotalPrice = async () => {
    const resData = await getProductsPrice({userId});
    console.log("calcTotalPrice=",resData);
    settingPriceObjs(resData);
  };

  const getHomeData = async () => {
    if(userId == null || !userId) {
      localStorage.setItem("login_redirect","cart");
      navigate("/login");
    }
    const data = {userId};
    const resData = await getCartList(data);
    console.log(resData);
    if (! resData ) return;
    let initialCounts = [];
    resData.list.forEach(obj => {
        initialCounts[obj._id] = obj.qty;
    });
    setLists(resData.list);
    setCounts(initialCounts);
    await calcTotalPrice();
    localStorage.setItem("cartcnt", resData.list.length);
    props.setCartCount(resData.list.length);
    setCartCount(resData.list.length);
  };

    return (
    <>
    {cartCount === 0 ?
      <section className="cart-section section-b-space">
        <div className="container-fluid-lg">
                <div className="summery-contain text-center">
                  <ul>
                    <li><h1><svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
</svg></h1></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><h2 className="price p-1 theme-color">Your cart is empty!</h2></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><h4 className="price p-1">Explore our crazy deals</h4></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><Link to="/" >Continue shopping</Link></li>
                  </ul>
                </div>
                
        </div>
      </section>
      : cartCount > 0 &&
      <section className="cart-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-5 g-3">
            <div className="col-xxl-9 p-1">
              <div className="cart-table">
                {lists.map((list, index) => (
                  <div className="table-responsive-xl" style={{display: counts[list._id] > 0 ? 'block' : 'none' }}  key={index}>
                    <table className="table">
                      <tbody> 
                        <tr className="product-box-contain">
                          <td className="product-detail">
                            <div className="product border-0">
                              <Link className="product-image" to={`/product/${list.productId?._id}/${list.productId?.product_name}`}>
                                <img
                                  src={process.env.REACT_APP_DB_URL+`/uploads/${list.productId?.images[0]}`}
                                  className="img-fluid blur-up lazyloaded"
                                  alt=""
                                />
                              </Link>
                              <div className="product-details">
                                <ul>
                                {/* {list._id} */}
                                  <li className="name">
                                    <Link to={`/product/${list.productId?._id}/${list.productId?.product_name}`}>
                                      {list.productId.display === false && totalPrice < 500 ?
                                      <><s>{list.productId?.product_name}</s></>
                                      :
                                      <>{list.productId?.product_name}</>
                                      }
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {list.productId.display === false ?
                            <>
                            { totalPrice < 500 ?
                              <>
                              <div className="row">
                                <span class="badge bg-primary"><h5>Please add &#8377;{500 - totalPrice} more to get this free gift.</h5></span>
                              </div>
                              </>
                              :
                              <>
                              {/*
                                <div className="row">
                                  <span class="badge bg-warning">Provided free gift on first purchase of order</span>
                                </div>
                              */}
                              </>
                            }
                            </>
                            :
                            ""
                            }
                          </td>
                          <td className="price">
                           <h4 className="table-title text-content">Price</h4>
                            <h5>
                              <del className="text-content">₹{list.productId?.price}</del>
                            </h5>
                            <h5>
                              <div className="text-content">₹{list.productId?.sale_price}</div>
                            </h5>
                            {/* <h6 className="theme-color">You Save : ₹{list.productId.discount_price} </h6> */}
                          </td>
                          <td className="quantity">
                            <h4 className="table-title text-content">Qty </h4>

                            <div className="quantity-price">
                              <div className="cart_qty">
                                <div className="input-group">
                                  <button
                                    onClick={() =>
                                      _update(
                                        counts[list._id] > 1
                                          ? counts[list._id] - 1
                                          : 1,
                                        list._id,
                                        list.productId?._id,
                                        list.productId?.instock,
                                        list.productId?.display

                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  {counts[list._id]}
                                  <button
                                    onClick={() =>
                                      _update(counts[list._id] + 1,
                                              list._id,
                                              list.productId?._id,
                                              list.productId?.instock,
                                              list.productId?.display
                                              )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="subtotal">
                            <h4 className="table-title text-content">Total</h4>
                            <h5>
                              ₹{list.productId?.sale_price * counts[list._id]}
                            </h5>
                          </td>
                          <td className="save-remove">
                            <h4 className="table-title text-content">Action</h4>

                            <a
                              className="remove close_button"
                              onClick={() =>
                                localStorage.setItem("cartId", list._id)
                              }
                            >
                              <Link to="#" className="remove close_button" 
                                onClick={() =>
                                  _update(0, list._id, list.productId?._id,list.productId?.instock,list.productId?.display)
                                }
                              > Remove
                              </Link>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xxl-3  p-1">
              <div className="summery-box p-sticky">
                <div className="summery-header">
                  <h3>Cart Total</h3>
                </div>
                <div className="summery-contain">
                  {/*
                   <ul>
                    <li>
                    <h4><div class="fb-share-button" data-href="https://admin.krazeal.com/uploads/agn-2.jpg" data-layout=""data-size=""><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fadmin.krazeal.com%2Fuploads%2Fagn-2.jpg&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a></div> to get 500Rs gift on first purchase</h4>
                    </li>
                   </ul>
                  */}
                  { couponCode && isCouponApplied &&
                  <ul>
                    <li>
                      <h4 className="price p-1 color">Applied Coupon {couponCode} </h4>
                      <h4 className="price p-1"><button className="btn btn-sm btn-danger" onClick={() => couponClick("remove")}>X</button></h4>
                    </li>
                    <li>
                      {couponError ? 
                      <h6 className="price p-1 text-danger">{Parser(couponError)} !</h6>
                      : couponSuccess != "" &&
                      <h6 className="price p-1 text-success">{Parser(couponSuccess)} !</h6>
                      }
                    </li>
                  </ul>
                  }
                  {( !couponCode || !isCouponApplied) &&
                  <div className="coupon-cart">
                    <h6 className="text-content mb-2">Apply Coupon</h6>
                    <div className="mb-3 coupon-box input-group">
                      <input
                        type="email"
                        ref={couponInputRef}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Coupon Code Here..."
                      />
                      <button className="btn-apply" onClick={() => couponClick("apply")}>Apply</button>
                    </div>
                    <ul>
                      <li>
                      {couponError ? 
                      <h4 className="price p-1 text-danger">{Parser(couponError)} !</h4>
                      : couponSuccess != "" &&
                      <h4 className="price p-1 text-success">{Parser(couponSuccess)} !</h4>
                      }
                      </li>
                    </ul>
                  </div>
                  }
                  <ul>
                    <li>
                      <h4>Price ({cartCount} items)</h4>
                      <h4 className="price p-1">₹{actualPrice}</h4>
                    </li>
                    <li>
                      <h4>Krazeal Discount</h4>
                      <h4 className="price p-1 text-success">(-) ₹{actualPrice - totalPrice}</h4>
                    </li>
                    {couponDiscount > 0 && 
                    <li>
                      <h4>Coupon Discount</h4>
                      <h4 className="price p-1 text-success">(-) ₹{couponDiscount}</h4>
                    </li>
                    }
                    <li className="align-items-start">
                      <h4>Delivery Charges</h4>
                      {deliveryDiscount > 0 ?
                      <h4 className="price text-end text-success"><del>₹{deliveryCharges}</del>&nbsp;({ deliveryCharges - deliveryDiscount === 0 ? "free" : (deliveryCharges - deliveryDiscount)})</h4>
                      :
                      <h4 className="price text-end">₹{deliveryCharges}</h4>
                      }
                    </li>
                    {deliveryMsg && deliveryMsg != "" && ! deliveryDiscount > 0 ?
                    <li className="align-items-start">
                      <h6 className="price p-1 text-danger">{Parser(deliveryMsg)} !</h6>
                    </li>
                    :
                    ""
                    }
                  </ul>
                </div>
                <ul className="summery-total">
                  <li className="list-total border-top-0">
                    <h4>Total </h4>
                    <h4 className="price theme-color">₹{paymentNeeded}</h4>
                  </li>
                  <li>
                    <h5 className="price text-success text-bold">You will save ₹{actualPrice - paymentNeeded} on this order</h5>
                  </li>
                </ul>
                <div className="button-group cart-button">
                  <ul>
                    <li>
                      <button
                        className="btn btn-animation proceed-btn fw-bold"
                        to="/checkout"
                        
                      >
                        <Ordersummery price={totalPrice} />
                      </button>
                    </li>
                    
                    <li>
                      
                      <Link to="/">
                      <button
                        className="btn btn-light shopping-button text-dark"
                      >
                        <i className="fa fa-arrow-left-long" />
                        Return To Shopping
                      </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
				<ToastContainer position="bottom-center" />
      </section>
    }
    </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
