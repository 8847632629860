import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {getUserDetails, updateUser} from "../utils/DatabaseAccess.js";
import Autocompletesearch from "./autocompleteSearch.js";
const SearchMobileWindow = (props) => {
  return (
    <>
       <div className="modal theme-modal" id="searchWindow" role="dialog" data-bs-keyboard="true" >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-sm-down">       
         <div className="modal-content">
          <form >
            <div className="modal-body">
            <Autocompletesearch setSearch={props.setSearch}/>
            </div>
          </form>
         </div>
          
        </div>
       </div>
    </>
  )
}

export default SearchMobileWindow
