import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { Pagination } from "swiper";
import ImageGallery from "react-image-gallery";
import { useNavigate, useParams } from "react-router-dom";
import AddToCart from "./AddToCart";
import StarRating from "./StarRating";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/Redux';
import {scrollToTop,Star,DbToHtmlFormatting,urlEncode} from "../utils/Generic";
import {getProductDetails} from "../utils/DatabaseAccess";
import { useSearchParams } from "react-router-dom";
import Parser from 'html-react-parser';

const Product = (props) => {
  const [list, setList] = useState([]);
  const [user, setUser] = useState(null);
  const [counter, setCounter] = useState(1);
  const [loader, setLoading] = useState(false);
  const [productObj, setProductObj] = useState([]);
  const [categoryObj, setCategoryObj] = useState([]);
  const [imageArr, setImageArr] = useState([]);
  const [globalRating, setGlobalRating] = useState(null);
  const [krazealRating, setKrazealRating] = useState(null);
  const [flipKartRating, setFlipKartRating] = useState(null);
  const [amazonRating, setAmazonRating] = useState(null);
  const [queryParam] = useSearchParams();
  //const [productId, setProductId]=useState(queryParam.get("pid") || "");
  const [additionalInfo,setAdditionalInfo] = useState("");
  const [productDetails,setProductDetails] = useState("");
  const [description,setDescription] = useState("");
  const  {productId,productName} = useParams();

  useEffect(() => {
    scrollToTop();
    ProductInit();
  }, []);
  const ProductInit = async () => {
    const userid = localStorage.getItem("useriid");
    if (userid) {
        setUser(userid);
    }
    setLoading(true);
    const data = {userid,productId};
    const pObj = await getProductDetails(data);
    console.log(pObj);
    if(pObj) {
      setLoading(false);
      setProductObj(pObj);
      setCategoryObj(pObj.category_id);
      const gRating = pObj.global_rating;
      const rating = pObj.rating;
      setKrazealRating(rating);
      if (gRating) {
          setFlipKartRating(gRating?.flipkart);
          setAmazonRating(gRating?.amazon);
          var overall_rating = 0;
          var cnt = 0;
          if (gRating?.flipkart){
            cnt++;
            overall_rating += gRating?.flipkart;
          }
          if (gRating?.amazon){
            cnt++;
            overall_rating += gRating?.amazon;
          }
          if (rating){
            cnt++;
            overall_rating += rating;
          }
          if(overall_rating != 0){
            overall_rating = parseFloat((overall_rating/cnt).toFixed(1));
          }
          setGlobalRating(overall_rating)
      }
      setAdditionalInfo(DbToHtmlFormatting(pObj.additional_info));
      setProductDetails(DbToHtmlFormatting(pObj.product_details));
      setDescription(DbToHtmlFormatting(pObj.description));
      if (pObj.images) {
        let data = [];
        pObj.images.map((item, index) => {
          data.push({
            original: process.env.REACT_APP_DB_URL+"/uploads/" + item,
            thumbnail: process.env.REACT_APP_DB_URL+"/uploads/" + item,
          });
        });
        setImageArr(data);
      }
    }
    setLoading(false);
  };
  const handleCountChange = (event) => {
    setCounter(Number(event.target.value));
  };

	// for magnify image : https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_image_zoom

  return (
    <>
      <section className="product-section">
        <div className="container-fluid-lg">
          <div className="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp" style={{ visibility: "visible", animationName: "fadeInUp" }}>
              <div className="row g-4">
              <div class="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp" >
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li class="breadcrumb-item"><Link to={`/category/${categoryObj._id}/` + urlEncode(categoryObj.name)}>{categoryObj.name}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{productObj.product_name}</li>
                  </ol>
                </nav>
              </div>
              <div className="col-xl-6 wow fadeInUp"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
              >
                  <div className="product-left-box">
                        <div className="wrapper">
                          <ImageGallery
                            autoplay={false}
                            showFullscreenButton={false}
                            PlayPauseButton={false}
                            showArrows={false}
                            showPlayButton={false}
                            arrows={false}
                            infinite={true}
                            items={imageArr}
                          />
                        </div>
                  </div>
              </div>
              <div
                  className="col-xl-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp",
                  }}
              >
                  <div className="right-box-contain">
                      <div className="row">
                      <div className="col-md-7">
                      <h2 className="name">{productObj.product_name}</h2>
                        <div className="price-rating">
                          <h3 class="theme-color price"> ₹{productObj.sale_price}<del class="text-content"> ₹{productObj.price} </del><span class="offer theme-color">{productObj.discount_percentage}% Off</span></h3>
                        </div> 
                        {globalRating && globalRating > 0 ?
                         <><br/><div class="price-rating custom-rate">Overall Rating &nbsp; <span class="badge bg-warning text-white">{globalRating}</span></div></>
                         :
                         <></>
                        }
                      </div>
                      <div className="col-md-5">
                        {amazonRating && <><span class="review">Amazon Rating</span>&nbsp;<span>{Star(amazonRating)}</span><br/></>}
                        {flipKartRating && <><span class="review">Flipkart Rating</span>&nbsp;<span>{Star(flipKartRating)}</span><br/></>}
                        {krazealRating && <><span class="review">Krazeal Rating</span>&nbsp;<span>{Star(krazealRating)}</span></>}
                      </div>
                      </div>
                    
                    
                    <div className="procuct-contain">
                      <p>{productDetails}</p>
                    </div>
                   
                    <div className="col-sm-12" style={productObj?.instock < 6 ? {display:"block"} : {display:"none"} }>
                      {productObj?.instock > 0 &&
                      <>
                      <div class="p-3 mb-2 bg-light text-dark">
                            Stock :{" "}
                            {productObj?.instock} Items Left
                      </div>
                      </>
                      }
                    </div>
                      <div class="p-3 mb-2 bg-light text-dark">
                        {productObj?.return_policy ?
                        <>Return : 10 days return policy</>
                        :
                        <>Return : No Returns Applicable</>
                        }
                      </div>
                      <div class="p-3 mb-2 bg-light text-dark">
                        <>Delivered at : Bangalore only within 2 business days</>
                      </div>
                      <div className="p-3 col-sm-3 ">
                        <AddToCart
                          count={counter}
                          price={productObj?.sale_price * counter}
                          redux={props}
                          out_of_stock={productObj?.instock > 0 ? false : true}
                          display={productObj?.display}
                        />
                      </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="product-section-box">
                    <ul
                      className="nav nav-tabs custom-nav"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description"
                          type="button"
                          role="tab"
                          aria-controls="description"
                          aria-selected="true"
                        >
                          Product Description{" "}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="info-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#info"
                          type="button"
                          role="tab"
                          aria-controls="info"
                          aria-selected="false"
                          style={additionalInfo ? {display:"block"} : {display:"none"} }
                        >
                          Additional info
                        </button>
                      </li>
                      {/*
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="review-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#review"
                          type="button"
                          role="tab"
                          aria-controls="review"
                          aria-selected="false"
                        >
                          Review
                        </button>
                      </li>
                      */}
                    </ul>
                    <div className="tab-content custom-tab" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="product-description">
                          <div className="nav-desh">
                            <p>{description}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="info"
                        role="tabpanel"
                        aria-labelledby="info-tab"
                      >
                        
                        <div className="table-responsive">
                          {additionalInfo}

                          {/*
                          <table className="table info-table">
                            <tbody>
                              <tr>
                                <td>Specialty</td>
                                <td>Vegetarian</td>
                              </tr>
                              <tr>
                                <td>Ingredient Type</td>
                                <td>Vegetarian</td>
                              </tr>
                              <tr>
                                <td>Brand</td>
                                <td>Lavian Exotique</td>
                              </tr>
                              <tr>
                                <td>Form</td>
                                <td>Bar Brownie</td>
                              </tr>
                            </tbody>
                          </table>
                          */}
                        </div>
                      </div>
                      {/*
                      <div
                        className="tab-pane fade"
                        id="review"
                        role="tabpanel"
                        aria-labelledby="review-tab"
                      >
                        <div className="review-box">
                          <div className="row g-4">
                            <div className="col-xl-6">
                              <div className="review-title">
                                <h4 className="fw-500">Customer reviews</h4>
                              </div>
                              <div className="d-flex">
                                <div className="product-rating">
                                  <ul className="rating">
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star fill"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                      </svg>
                                    </li>
                                  </ul>
                                </div>
                                <h6 className="ms-3">4.2 Out Of 5</h6>
                              </div>
                              <div className="rating-box">
                                <ul>
                                  <li>
                                    <div className="rating-list">
                                      <h5>5 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "68%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          68%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>4 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "67%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          67%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>3 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "42%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          42%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>2 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "30%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          30%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>1 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "24%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          24%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="review-title">
                                <h4 className="fw-500">Add a review</h4>
                              </div>
                              <div className="row g-4">
                                <div className="col-md-6">
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      placeholder="Name"
                                    />
                                    <label htmlFor="name">Your Name</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      placeholder="Email Address"
                                    />
                                    <label htmlFor="email">Email Address</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="url"
                                      className="form-control"
                                      id="website"
                                      placeholder="Website"
                                    />
                                    <label htmlFor="website">Website</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="url"
                                      className="form-control"
                                      id="review1"
                                      placeholder="Give your review a title"
                                    />
                                    <label htmlFor="review1">
                                      Review Title
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-floating theme-form-floating">
                                    <textarea
                                      className="form-control"
                                      placeholder="Leave a comment here"
                                      id="floatingTextarea2"
                                      style={{ height: "150px" }}
                                      defaultValue={""}
                                    />
                                    <label htmlFor="floatingTextarea2">
                                      Write Your Comment
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="review-title">
                                <h4 className="fw-500">
                                  Customer questions &amp; answers
                                </h4>
                              </div>
                              <div className="review-people">
                                <ul className="review-list">
                                  <li>
                                    <div className="people-box">
                                      <div>
                                        <div className="people-image">
                                          <img
                                            src="../assets/images/review/1.jpg"
                                            className="img-fluid blur-up lazyload"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="people-comment">
                                        <a
                                          className="name"
                                          href="javascript:void(0)"
                                        >
                                          Tracey
                                        </a>
                                        <div className="date-time">
                                          <h6 className="text-content">
                                            14 Jan, 2022 at 12.58 AM
                                          </h6>
                                          <div className="product-rating">
                                            <ul className="rating">
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="reply">
                                          <p>
                                            Icing cookie carrot cake chocolate
                                            cake sugar plum jelly-o danish.
                                            Dragée dragée shortbread tootsie
                                            roll croissant muffin cake I love
                                            gummi bears. Candy canes ice cream
                                            caramels tiramisu marshmallow cake
                                            shortbread candy canes cookie.
                                            <a href="javascript:void(0)">
                                              Reply
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="people-box">
                                      <div>
                                        <div className="people-image">
                                          <img
                                            src="../assets/images/review/2.jpg"
                                            className="img-fluid blur-up lazyload"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="people-comment">
                                        <a
                                          className="name"
                                          href="javascript:void(0)"
                                        >
                                          Olivia
                                        </a>
                                        <div className="date-time">
                                          <h6 className="text-content">
                                            01 May, 2022 at 08.31 AM
                                          </h6>
                                          <div className="product-rating">
                                            <ul className="rating">
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="reply">
                                          <p>
                                            Tootsie roll cake danish halvah
                                            powder Tootsie roll candy
                                            marshmallow cookie brownie apple pie
                                            pudding brownie chocolate bar.
                                            Jujubes gummi bears I love powder
                                            danish oat cake tart croissant.
                                            <a href="javascript:void(0)">
                                              Reply
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="people-box">
                                      <div>
                                        <div className="people-image">
                                          <img
                                            src="../assets/images/review/3.jpg"
                                            className="img-fluid blur-up lazyload"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="people-comment">
                                        <a
                                          className="name"
                                          href="javascript:void(0)"
                                        >
                                          Gabrielle
                                        </a>
                                        <div className="date-time">
                                          <h6 className="text-content">
                                            21 May, 2022 at 05.52 PM
                                          </h6>
                                          <div className="product-rating">
                                            <ul className="rating">
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star fill"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                              <li>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="feather feather-star"
                                                >
                                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                </svg>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="reply">
                                          <p>
                                            Biscuit chupa chups gummies powder I
                                            love sweet pudding jelly beans.
                                            Lemon drops marzipan apple pie
                                            gingerbread macaroon croissant
                                            cotton candy pastry wafer. Carrot
                                            cake halvah I love tart caramels
                                            pudding icing chocolate gummi bears.
                                            Gummi bears danish cotton candy
                                            muffin marzipan caramels awesome
                                            feel.{" "}
                                            <a href="javascript:void(0)">
                                              Reply
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                          */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-list-section section-b-space">
      {/*
        <div className="container-fluid-lg">
          <div className="title">
            <h2>Related Products</h2>
            <span className="title-leaf">
              <svg className="icon-width">
                <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
              </svg>
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <Swiper
                slidesPerView={5}
                spaceBetween={1}
                loop={true}
                autoplay={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                style={{ overflow: "hidden" }}
              >
                <div className="slider-6_1 product-wrapper">
                  <SwiperSlide>
                    {list.map((item) => {
                      let idData = item._id[0];

                      //let image= 'http://103.104.74.215:3018/uploads/'+item.image[0];
                      return (
                        <div
                          className="product-box-3 wow fadeInUp"
                          onClick={() =>
                            localStorage.setItem(
                              "pro_id",
                              item._id,
                              "item.name",
                              item.name
                            )
                          }
                          key={item.id}
                        >
                          <div className="product-header">
                            <div className="product-image">
                              <Link to="/product">
                                <img
                                  src={
                                    process.env.REACT_APP_DB_URL+"/uploads/" +
                                    item.images[0]
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="product-footer">
                            <div className="product-detail">
                              <Link to="/product">
                                <h5 className="name">{item.product_name}</h5>
                              </Link>
                              <div className="product-rating mt-2">
                                <ul className="rating">
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-star fill"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                    </svg>
                                  </li>
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-star fill"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                    </svg>
                                  </li>
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-star"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                    </svg>
                                  </li>
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-star"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                    </svg>
                                  </li>
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-star"
                                    >
                                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                    </svg>
                                  </li>
                                </ul>
                              </div>
                              <h5 className="price ">
                                ₹{item.sale_price}
                                <del>₹{item.price}</del>
                                <p className="offers "></p>{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      */}
      </section>

      <div className="sticky-bottom-cart">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="cart-content">
                <div className="product-image">
                  <img
                    src="../assets/images/product/category/1.jpg"
                    className="img-fluid blur-up lazyloaded"
                    alt=""
                  />
                  <div className="content">
                    <h5>Creamy Chocolate Cake</h5>
                    <h6>
                      $32.96<del className="text-danger">$96.00</del>
                      <span>55% off</span>
                    </h6>
                  </div>
                </div>
                <div className="selection-section">
                  <div className="cart_qty qty-box product-qty m-0">
                    <div className="input-group h-100">
                      <button
                        type="button"
                        className="qty-left-minus"
                        data-type="minus"
                        data-field
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-minus"
                        >
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                      </button>
                      <input
                        className="form-control input-number qty-input"
                        type="text"
                        name="quantity"
                        defaultValue={1}
                      />
                      <button
                        type="button"
                        className="qty-right-plus"
                        data-type="plus"
                        data-field
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-plus"
                        >
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={5} y1={12} x2={19} y2={12} />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer2/> */}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
