import { createStore } from 'redux';

const initialState = {
  count: -1
};

// This is the REDUCER, which is responsible for changing the state.
// The underlying state remains, I believe, but a new object is assigned,
// which acts as the changed state.
const reducer = (state = initialState, action) => {
  switch(action.type) {
    case "SET":
      return Object.assign({}, state, {count: parseInt(action.count)})
    case "INCREMENT":
      return Object.assign({}, state, {count: state.count + 1})
    case "DECREMENT":
      return Object.assign({}, state, {count: state.count ? state.count - 1 : 0})
    case "RESET":
      return Object.assign({}, state, {count: state.count = 0})
    default:
      return state;
  }
};

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
