import axios from "axios";

const getProductsPrice  = async (data) => {
     const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    console.log(data);
    const userId = data.userId;
    if (userId == null && localStorage.getItem("cart") && JSON.stringify(cartObj) != "{}") {
        var cartObj = JSON.parse(localStorage.getItem("cart"));
        data['cartObj'] = cartObj;
    }
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/products_price",
        data,
        options
      );
      if (res.data.result) {
          return (res.data.data);
      }
      return ("")
    } catch (err) {
      console.error(err);
      return ("");
    }
}

const getReturnProductPrice  = async (data) => {
     const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    console.log(data);
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/product_return_price",
        data,
        options
      );
      if (res.data.result) {
          return (res.data.data);
      }
      return ("")
    } catch (err) {
      console.error(err);
      return ("");
    }
}

const cancelOrder  = async (data) => {
     const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/cancel_order",
        data,
        options
      );
      if (res.data.result) {
          return (res.data.data);
      }
      return ("")
    } catch (err) {
      console.error(err);
      return ("");
    }
}
const returnOrder  = async (data) => {
     const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/return_order",
        data,
        options
      );
      return (res.data.data);
    } catch (err) {
      console.error(err);
      return ("");
    }
}
const getOrdersHistory = async (data) => {
     const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/orders_history",
        data,
        options
      );
      return (res.data.data);
    } catch (err) {
      console.error(err);
      return ("");
    }
}

const getAddressList = async (data) => {
    if (data.userId == undefined) {
        return [];
    }
     const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/address_list",
        data,
        options
      );
      return (res.data.data);
    } catch (err) {
      console.error(err);
      return ("");
    }
}

const getPaymentUrl = async (data) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/payment/make-payment",
        data
      );
      return (res.data);
    } catch (err) {
      console.error(err);
      return ("");
    }
}

const createDbOrder = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/create_order",
        data,
        options
      );
      return (response.data);
    } catch (err) {
      console.error(err);
    }
}
const updateCart = async (data) => {
    const userId = data.userId;
    if (userId == null) {
        let cartObj = {};
        if(localStorage.getItem("cart") != null) {
            cartObj = JSON.parse(localStorage.getItem("cart"));
        }
        cartObj[data['productId']].qty = data.qty;
        localStorage.setItem("cart", JSON.stringify(cartObj));
        return
    }
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/update_cart",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}
const removeCart = async (data) => {
    const userId = data.userId;
    if (userId == null) {
        let cartObj = {};
        if(localStorage.getItem("cart") != null) {
            cartObj = JSON.parse(localStorage.getItem("cart"));
        }
        delete cartObj[data['productId']];
        localStorage.setItem("cart", JSON.stringify(cartObj));
        return
    }
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/cart_remove",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}
const emptyCart = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
        await axios.post(
        process.env.REACT_APP_DB_URL+"/api/cart_empty",
        data,
        options
      );
    } catch (err) {
      console.error(err);
    }
}
const getProductDetails = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/product_details",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}
const getCartList = async (data) => {
    const userId = data.userId;
    let cartObj = {};
    if(localStorage.getItem("cart") != null) {
        cartObj = JSON.parse(localStorage.getItem("cart"));
    }
    if (JSON.stringify(cartObj) != "{}") {
        let list = [];
        if (userId) {
            await emptyCart({userId});
        }
        for (const key in cartObj) {
            let obj = cartObj[key];
            if (key == undefined ) continue;
            const prod_res = await getProductDetails({productId : key});
            obj['productId'] = prod_res;
            if (userId) {
                obj['userId'] = userId;
                addToCart(obj);
            }
            list.push(obj);
        }
        if (userId) {
            localStorage.removeItem("cart")
        }
    }
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/cart_list",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}
const getOrdersFromSummaryId = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/orders_from_summary_id",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}

const getSummaryInfoFromId = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/summary_info_from_id",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}

const getUserDetails = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/user_details",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
}
const generateOrderInvoice = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/generate_order_invoice",
        data,
        options
      );
      console.log(res);
      var invoiceData = res.data.data;
      if (invoiceData.is_invoice_created) {
          return invoiceData.invoice_url;
      }
    } catch (err) {
      console.error(err);
    }
    return "";
}

const addToCart = async (data) => {
    const userId = data.userId;
    if (userId == null) {
        let cartObj = {};
        if(localStorage.getItem("cart") != null) {
            cartObj = JSON.parse(localStorage.getItem("cart"));
        }
        data['_id'] = Math.floor(Math.random() * 10000)
        cartObj[data['productId']] = data;
        localStorage.setItem("cart", JSON.stringify(cartObj));
        return
    }

    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios.post(process.env.REACT_APP_DB_URL+"/api/add_cart",data,options).then(res => {
          return res.data.data;
    }).catch(err =>{
        return err;
    });
    return "";
}
const extractDataFromPincode = async (pincode) => {
    const data ={pincode};
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/get_locality_details",
        data,
        options
      );
      return (response.data.data);
    } catch (err) {
      console.error(err);
    }
    return {};
}

const addNewAddress = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios.post(process.env.REACT_APP_DB_URL+"/api/add_address" , data, options).then((response)=>{
        return (response.data.result);
    }).catch((err)=>{
        console.log(err);
    });
};

const updateAddress = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios.post(process.env.REACT_APP_DB_URL+"/api/address_update", data, options).then((response)=>{
        return (response.data.result);
    }).catch((err)=>{
        console.log(err);
    });
};

const deleteAddress = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios.post(process.env.REACT_APP_DB_URL+"/api/address_delete", data, options).then((response)=>{
        return (response.data.result);
    }).catch((err)=>{
        console.log(err);
    });
};

const loginUser = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios.post(process.env.REACT_APP_DB_URL+'/api/login', data, options).then((response) => {
        console.log("RESPONSE=",response.data);
        const res = response.data;
        return res;
    }).catch((err) => {
        return ({
            result:"false",
            message:err.message
        });
    });
};

const getRegeneratedOtp = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    console.log("regenerateOtp start");
    let resData = "";
    await axios.post(process.env.REACT_APP_DB_URL+'/api/regenerate_otp', data, options).then((response) => {
        console.log("regenerateOtp response=",response);
        resData = response.data;
    }).catch((err) => {
        return ({
            result:"false",
            message:err.message
        });
    });
    return resData;
    console.log("regenerateOtp end");
};
const updateUser = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios.post(process.env.REACT_APP_DB_URL+'/api/update_userdata', data, options).then((response) => {
        return response.data;
    }).catch((err) => {
        return ({
            result:"false",
            message:err.message
        });
    });
};

const applyCoupon = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(process.env.REACT_APP_DB_URL+"/api/apply_coupon",data,options);
      return (response.data);
    } catch (err) {
      console.error(err);
    }
};

const getProductsSearch = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(process.env.REACT_APP_DB_URL+"/api/search_api",data,options);
      return (response.data);
    } catch (err) {
      console.error(err);
    }
};

const getProductsList = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.get(process.env.REACT_APP_DB_URL+"/api/product_list",data,options);
      return (response.data);
    } catch (err) {
      console.error(err);
    }
};

const getCategoryList = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.get(process.env.REACT_APP_DB_URL+"/api/category_list",data,options);
      return (response.data);
    } catch (err) {
      console.log(err);
    }
}

const getOrderDetails = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(process.env.REACT_APP_DB_URL+"/api/order_details",data,options);
      return (response.data);
    } catch (err) {
      console.log(err);
    }
}

const getProductsByCategory = async (data) => {
    const options = {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(process.env.REACT_APP_DB_URL+"/api/category_product_list",data,options);
      return (response.data);
    } catch (err) {
      console.log(err);
    }
}

export {
    getPaymentUrl,
    getAddressList,
    createDbOrder,
    getCartList,
    getOrdersFromSummaryId,
    getSummaryInfoFromId,
    generateOrderInvoice,
    getOrdersHistory,
    cancelOrder,
    returnOrder,
    getProductsPrice,
    updateCart,
    removeCart,
    addToCart,
    getUserDetails,
    extractDataFromPincode,
    addNewAddress,
    deleteAddress,
    updateAddress,
    loginUser,
    updateUser,
    getRegeneratedOtp,
    applyCoupon,
    getProductsSearch,
    getProductsList,
    getCategoryList,
    getReturnProductPrice,
    getOrderDetails,
    getProductDetails,
    getProductsByCategory
};
