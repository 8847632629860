import React,{ useState, useEffect }  from 'react'
import {scrollToTop,DbToHtmlFormatting} from "../utils/Generic";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState("");
  useEffect(() => {
    scrollToTop();
    PrivacyPolicyInit();
  }, []);

  const PrivacyPolicyInit = async () => {
    var raw_text = `
<center><h2>PRIVACY POLICY</h2></center>

We welcome you to Krazeal family. This Privacy Policy describes how Dealsmith India Private Limited with brand name Krazeal and e commerce website krazeal.com can collect, use, share or otherwise process your personal information through our website www.krazeal.com.

#1. Collection of Your Information

We collect your personal information relating to your identity, demographics and when you use our Platform, services or otherwise interact with us during the course of our relationship and related information provided from time to time. Some of the information that we may collect includes but is not limited to Information provided to us during sign-up/registering or using our Platform such as name, date of birth, address, telephone/mobile number, email ID, occupation and any such information shared as proof of identity or address. In general, you can browse the Platform without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. We shall not be responsible for the third-party business partner’s privacy practices or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information. If you set up an account or transact with us, we may seek some additional information, such as billing address, credit/debit card number and a credit/debit card expiration date and/ or other payment instrument details and tracking information from cheques or money orders to provide services, enable transactions or to refund for cancelled transactions You always have the option to not provide information, by choosing not to use a particular service or feature on the Platform.  

#2. Why We Collect Personal Information :

We use your personal information to take and fulfill orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers. We use your personal information to provide functionality, analyze performance, fix errors, and improve the usability. We use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with our Services. In certain cases, we collect and use your personal information to comply with laws.We use your personal information to communicate with you in relation to our Services via different channels (e.g., by phone, e- mail, chat). We use your personal information to display interest-based ads for features, products, and services that might be of interest to you. We do not use information that personally identifies you to display interest-based ads. We use personal information to prevent and detect fraud and abuse in order to protect the security of our customers, Amazon, and others. We may also use scoring methods to assess and manage credit risks.

#3. Where We Use This Personal Information

This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Platform or not), which URL you next go to (whether this URL ison our Platform or not), your computer browser information, and your IP address. we will collect and store your personal information such as name, contact number, email address, communication address, date of birth, gender, zip code, lifestyle information, demographic and work details which is provided by you to us or a third-party business partner that operates platforms where you can earn loyalty points for purchase of goods and services, and/or also redeem them. We may track your buying behavior, preferences, and other information that you choose to provide on our Platform. We use this information to do internal research on our users&#39; demographics, interests, and behavior to better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. We use your personal information to provide
the product and services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services; inform you about online and offline offers, products, services, and updates; customize and enhance your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection of information.Our primary goal in doing so is to provide you a safe, efficient, smooth, and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Platform to make your experience safer and easier.  If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information into a file specific to you.  In our efforts to continually improve our product and service offerings, we and our affiliates collect and analyze demographic and profile data about our users&#39; activity on our Platform. We identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify you and to gather broad demographic information.

#4. Do We Share Your Personal Information

Our primary goal in doing so is to provide you a safe, efficient, smooth, and customized experience. We may share your personal information internally within Krazeal Group entities, our other corporate entities, and affiliates to provide you access to the services and products offered by them. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out. We may disclose personal information to third parties. This disclosure may be required for us to provide you access to our services and products offered to you, to comply with our legal obligations, to enforce our user agreement, to facilitate our marketing and advertising activities, to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our services. We may disclose personal and sensitive personal information to government agencies or other authorised law enforcement agencies if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public. We and our affiliates will share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur, that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.

#5. About Cookies

We use data collection devices such as &quot;cookies&quot; on certain pages of the Platform to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. &quot;Cookies&quot; are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal information. We offer certain features that are only available through the use of a &quot;cookie&quot;. We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are &quot;session cookies,&quot; meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter &quot;cookies&quot; or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics help us understand how our customers use the site.  

#6. How Secure is My Personal Information

We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to our security guidelines to protect it against unauthorized access. Users are responsible for ensuring the protection of login and password records for their account.Our devices offer security features to protect them against unauthorized access and loss of data. You can control these features and configure them based on your needs. The transmission of information is not completely secure for reasons beyond our control. We adopt industry standard security measures and keep updating our systems from time to time to protect your personal information against any hacking or virus dissemination. However, by using the Platform, the users accept the security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform.

#7. Choice To Opt-Out

We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications after setting up an account with us. If you do not wish to receive promotional communications from us, then please mail us regarding same at…….] to unsubscribe/opt-out.

#8. Children Information

Use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. We do not knowingly solicit or collect personal information from children under the age of 18 years. If you have shared any personal information of children under the age of 18 years, you represent that you have the authority to do so and permit us to use the information in accordance with this Privacy Policy.

#9. Data Retention

We retain your personal information in accordance with applicable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you if we believe it may be necessary to prevent fraud or future abuse, to enable Krazeal to exercise its legal rights and/or defend against legal claims or if required by law or for other legitimate purposes. We may continue to retain your data in anonymised form for analytical and research purposes.

#10. Your Rights

We take every reasonable step to ensure that your personal information that we process is accurate and, where necessary, kept up to date, and any of your personal information that we process that you inform us is inaccurate (having regard to the purposes for which they are processed) is erased or rectified. You may access, correct, and update your personal information directly through the functionalities provided on the Platform.  You may delete certain non-mandatory information by logging into our website and visiting Profile and Settings sections. You can also write to us at the contact information provided below to assist you with these requests.  You have an option to withdraw your consent that you have already provided by writing to us at the contact information provided on website. Please mention “for withdrawal of consent” in the subject line of your communication. We will verify such requests before acting upon your request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related Terms of Use and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, such withdrawal may hamper your access to the Platform or restrict provision of our services to you for which we consider that information to be necessary.

#11. Consent

By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information (including sensitive personal information) on the Platform in accordance with this Privacy Policy. If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.  You, while providing your personal information over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties like Credit Bureaus ) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.  You have an option to withdraw your consent that you have already provided by writing to the Grievance Officer at the contact information provided below. Please mention “Withdrawal of consent for processing personal information” in your subject line of your communication. We will verify such requests before acting on our request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related terms of use and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, we reserve the right to restrict or deny the provision of our services for which we consider such information to be necessary.

#12. Changes to this Privacy Policy

Please check our Privacy Policy periodically for changes. We may update this Privacy Policy to reflect changes to our information practices. We will alert you to significant changes by posting the date our policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.  Grievance Officer In accordance with Information Technology Act 2000 and rules made there under, the name and contact

#details of the Grievance Officer are provided below

Designation:
Dealsmithn India Private Limited
Bengaluru District,
Email: enquiry@krazeal.com
Last Updated: 28th June 2023`

    var policy_text = DbToHtmlFormatting(raw_text);
    setPolicy( policy_text);
  }

  return (
    <>
        <div className="container-fluid-lg">
        <div className="col-sm-12">
        {policy}
        </div>
        </div>
    </>
  )

}
export default PrivacyPolicy
