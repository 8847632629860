import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {getUserDetails, updateUser} from "../utils/DatabaseAccess.js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
const UpdateUser = (props) => {
  
  const navigate = useNavigate();
  const [userObj, setUserObj] = useState(null);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [selectedGender, setSelectedGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [effectTrigger, setEffectTrigger] = useState(true);

  useEffect(() => {
    if(effectTrigger) {
      (async () => {
        await getHomeData();
      })();
    }
  }, [userObj]);
  function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const setFieldsErrors = async (data) => {
    let err_data = errors;
    for (var key in data) {
        err_data[key] = data[key];
    }
    setErrors(err_data);
    console.log(err_data);
  }
  const handleGenderChange =  (e) => {
    e.preventDefault();
    setSelectedGender(e.target.value);
  }

  const getHomeData = async () => {
    const userid = localStorage.getItem("useriid");
    if (userid) {
        const userData = await getUserDetails({userId:userid});
        if (userData) {
            setUserObj(userData); 
            setFname(userData?.fname);
            setLname(userData?.lname);
            setEmail(userData?.email);
            if(userData.gender)
                setSelectedGender(userData.gender);
        }
    }
    setEffectTrigger(false);
  }
  const handleSubmitUser = async (evt) => {
    evt.preventDefault();
    setEffectTrigger(true);
    let is_validation_passed = true;
    let gender = selectedGender;
    if (!fname || !fname?.match(/[a-zA-Z]/)) {
        await setFieldsErrors({fname : "Please enter First name !"});
        is_validation_passed = false;
    }
    if (fname && !fname?.match(/^[a-zA-Z\s+]+$/)) {
        await setFieldsErrors({fname : "Only Alphabets are allowed !"});
        is_validation_passed = false;
    }
    if (!lname || !lname.match(/\S+/)) {
        await setFieldsErrors({lname : "Please enter First name !"});
        is_validation_passed = false;
    }
    if (lname && !lname.match(/^[a-zA-Z\s+]+$/)) {
        await setFieldsErrors({lname : "Only Alphabets are allowed !"});
        is_validation_passed = false;
    }
    if (!gender) {
        await setFieldsErrors({gender : "Please select gender !"});
        //is_validation_passed = false;
    }
    if (!email || !email.match(/\S+/)) {
        await setFieldsErrors({email : "Please enter email address !"});
        is_validation_passed = false;
    }
    if (email && !email.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)) {
        await setFieldsErrors({email : "Please enter valid email address !"});
        is_validation_passed = false;
    }
    setEffectTrigger(false);
    if(!is_validation_passed)
        return 
    const userId = localStorage.getItem("useriid");
    await updateUser({userId,fname,lname,email,gender});
    await props.updateUserData();
    $('#editUserProfile').modal('hide');
  }
  return (
    <>
     {userObj && 
       <div className="modal fade theme-modal" id="editUserProfile" aria-labelledby="exampleModalLabel2" aria-modal="true" role="dialog" data-bs-keyboard="false" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-sm-down">       
         <div className="modal-content">
          <form >
            <div className="modal-header">
           
              <h5 className="modal-title" id="exampleModalLabel2">Edit Profile</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa fa-solid fa-xmark" />
              </button>
            </div>
            <div className="modal-body">
           
              <div className="row g-4">
                <div className="col-xxl-6">
                 
                    <div className="form-floating theme-form-floating">
                      <input type="text" className="form-control" data-constraints="@Required" name="fname" id="fname" value={fname} id="form-login-fname" placeholder='First Name' onChange={e => setFname(e.target.value)} />
                      <label htmlFor="fname">First Name</label>
                      
                    </div>
                    <div className="text-danger text-bold" >{errors.fname}</div>
                </div>
                <div className="col-xxl-6">
                 
                    <div className="form-floating theme-form-floating">
                      <input type="text" className="form-control" data-constraints="@Required" name="lname" value={lname} id="form-login-lname" placeholder="Last Name" onChange={e => setLname(e.target.value)} />
                      <label htmlFor="lname">Last Name</label>
                      
                    </div>
                    <div className="text-danger text-bold" >{errors.lname}</div>
                
                </div>
                <div className="col-xxl-6">
                  
                    <div className="form-floating theme-form-floating">
                    <input type="email" className="form-control" id="form-register-email"  data-constraints="@email @Required" name="email" value={email} placeholder="Email Address" onChange={e => setEmail(e.target.value)} />
                        <label htmlFor="email">Email Address</label>
                      
                    
                    </div>
                    <div className="text-danger text-bold" >{errors.email}</div>
                  
                </div>
                  <div className="col-xxl-6">
                   <RadioGroup
                          row
                          aria-labelledby="demo-form-control-label-placement"
                          name="position"
                          defaultValue="end"
                   >
                   <FormControlLabel
                        checked={selectedGender === 'Male'}
                        onChange={handleGenderChange}
                        value="Male"
                        control={<Radio />}
                        label="Male"
                        labelPlacement="start"
                   />
                   <FormControlLabel
                        checked={selectedGender === 'Female'}
                        onChange={handleGenderChange}
                        value="Female"
                        control={<Radio />}
                        label="Female"
                        labelPlacement="start"
                   />
                   </RadioGroup>
                   <div className="text-danger text-bold" >{errors.gender}</div>
                </div>
              </div>
              
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-animation btn-md fw-bold" data-bs-dismiss="modal">Close</button>
              <input type="button" onClick={handleSubmitUser} className="btn theme-bg-color btn-md fw-bold text-light" value="Save changes"/>
            </div>
          </form>
         </div>
          
        </div>
       </div>
     }
    </>
  )
}

export default UpdateUser
