import React from 'react'
import Header2 from '../common/Header2'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
      {/*
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>About Us</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="fa fa-solid fa-house" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}

      <section className="fresh-vegetable-section">
        <div className="container-fluid-lg">
          <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
                {/*
                <div className="col-xl-6 col-12 g-sm-4">
              
                
                  <div className="fresh-image-2">
                    <div className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("../assets/images/inner-page/log-in.png")', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', display: 'block'}}>
                      <img  src="../assets/images/inner-page/log-in.png" className="bg-img blur-up lazyloaded img-fluid" alt="" style={{display: 'none'}} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-12">

                    <div className="bg-size blur-up lazyloaded" style={{backgroundImage: 'url("../assets/images/inner-page/log-in.png")', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', display: 'block'}}>
                    </div>
                </div>
                */}
                 <div class="col-6">
                            <div class="fresh-image">
                                <div>
                                    <img src="../assets/images/inner-page/log-in.png"
                                        class="bg-img blur-up lazyload" alt=""/>
                                </div>
                            </div>
                  </div> 
            
            
             <div class="col-6">
              <div className="fresh-contain p-center-left">
                <div>
                  <div className="review-title">
                    <h2>About Us</h2>
                  </div>
                  <div className="delivery-list">
                    <p className="text-content">
                      <b>krazeal.com</b> (Dealsmith India Private Limited) is online platform for unique crazy deals among different product categories.
                    </p>
                    <p className="text-content">
                      <b>krazeal.com</b> provides online users a wide variety of deals at very lucrative prices. The rates for each category will be unique till the inventory lasts. Once a particular stock of any category or product is over we will be offering deals among other product categories.
                    </p>
                    <p className="text-content">
                      At Krazeal our aim is to provide offers on different product categories such as household, appliances, FMCG, Furniture, etc., at a price which no other platform can provide either online or offline. Our aim is to offer deals at unbeatable price offer with a pleasant consumer experience.
                    </p>
                    <p className="text-content">
                      Team Krazeal is backed up with vast experience in media and product trading and IT segment for more than 15 years.
                    </p>
                    <p className="text-content">
                      The solidity of our team makes sure for our users to be happy with beneficial offers that we provide at our e-commerce platform.
                    </p>
                    <p className="text-content">
                      Krazeal confirms to make it’s e commerce model for the customers to discover astonishing deals and offers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-12"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
