import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getOrdersHistory } from "../utils/DatabaseAccess.js"
import {humanDateTime} from "../utils/Generic.js"
import { useNavigate } from "react-router-dom";

function Profileorderlist() {
  const navigate = useNavigate();
  const [lists, setLists] = useState([]);
  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = async () => {
    const userid = localStorage.getItem("useriid");
    if (!userid) {
        navigate("/login", { replace: true });
        return;
    }
    const data = JSON.stringify({
      userId: userid,
      lastDisplay:20
    });
    var ordersList = await getOrdersHistory(data);
    setLists(ordersList);
  };

  return (
    <div
      className="tab-pane fade show"
      id="pills-order"
      role="tabpanel"
      aria-labelledby="pills-order-tab"
    >
      <div className="dashboard-order">
        <div className="title">
          <h2>Orders History</h2>
          <span className="title-leaf title-leaf-gray">
            <svg className="icon-width bg-gray">
              <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
            </svg>
          </span>
        </div>
        <div className="order-contain">
          {lists.map((list) => {
            return (
              <div className="order-box col-sm-12">
                <div className="product-order-detail" style={{marginTop:'0px'}}>
                  <div className="col-lg-1">
                  <Link to={`/order-tracking/${list.orderSummaryId._id}/${list._id}`}>
                    <img
                      style={{ height: "100px", width: "100%" }}
                      src={
                        process.env.REACT_APP_DB_URL+"/uploads/" +
                        list.productInfo.product_image
                      }
                      className="blur-up lazyload"
                      alt=""
                    />
                  </Link>
                  </div>
                    <div className="col-lg-3">
                    <Link to={list.orderSummaryId && "/order-tracking/"+list.orderSummaryId._id+"/"+list._id} className="order-image">
                      <h5>{list.productInfo.product_name}</h5>
                    </Link>
                    </div>
                    <div className="col-lg-1">
                        <div className="size-box text-center">
                          <h5>{list.qty > 1 && `${list.qty} items at` } <b>₹{list.productInfo.sale_price * list.qty}</b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="size-box text-center">
                          <h6 className="text-content">
                          {list?.status === "NEW" || list?.status === "placed" || list?.status === "failed" ? humanDateTime(list.orderSummaryId.shippingInfo?.placed_timestamp,list?.status) : list?.status === "cancelled" ? humanDateTime(list.orderSummaryId.cancelShippingInfo?.cancelled_timestamp,list?.status) : list?.status === "delivered" && humanDateTime(list.orderSummaryId.shippingInfo?.delivered_timestamp,list?.status)}
                          </h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="size-box text-center">
                          { ! list?.orderSummaryId?.shippingInfo?.delivered_timestamp && list?.orderSummaryId?.shippingInfo?.etd ?
                          <h5>Expected delivered by {humanDateTime(list.orderSummaryId.shippingInfo?.etd)}</h5> 
                          :
                          <h5></h5> 
                          }
                        </div>
                    </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Profileorderlist;
