import { Link } from 'react-router-dom'
import Placeorder from './Placeorder'
import React from 'react'
// import Footer from '../common/Footer'
// import Header from '../common/Header'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const OrderCancel = () => {
  return (
    <>
    {/* <Header/> */}
       <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain breadscrumb-order">
                  <div className="order-image text-danger">
                    <ReportGmailerrorredIcon
                       style={{fontSize: "200px"}}
                    />
                  </div>
                  <div className="order-contain">
                    <h3 className="text-danger">Payment Failed</h3><br/>
                    <h5 className="text-content">Please try again or use another mode of payment</h5>
                    <br />
                    <h2 className="price theme-color">
                      <Link to="/check-out" className="text-success">Retry payment</Link>
                    </h2>
                    <br />
                    <p> Have a question?<a href="#"> Contact Support</a></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer/> */}
    </>
  )
}

export default OrderCancel
