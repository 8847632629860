import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../common/Header.js";
import { getUserDetails, getRegeneratedOtp, verifyOtp } from "../utils/DatabaseAccess.js";


function Otp() {
  const [output, setOutput] = useState('');
  const [userOtp, setUserOtp] = useState('');
  const [lists, setLists] = useState('');
  const [counter, setCounter] = useState(20);
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [userObj, setUserObj] = useState("");
  const [phone, setPhone] = useState("");
  const [sendOtpMsg, setSendOtpMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const phoneno = localStorage.getItem('validate-phone');
    if (!phoneno) {
      // Redirect user to the login page if the phone number is missing
      navigate('/login');
    } else {
      setPhone(phoneno);

      otpTimer(); // Start OTP timer if phoneno exists
    }
  }, []);
  const otpTimer = () => {
    counter > 0 && setInterval(() => {
      setCounter((time) => time - 1);
    }, 1000);
  }
  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!userOtp) {
      setOutput('*OTP is required');
      return;
    }

    if (userOtp.length !== 4) {
      setOutput('*Enter a 4-digit OTP');
      return;
    }

    try {
      // First, verify the OTP
      const otpResponse = await verifyOtp({ phone, otp: userOtp });

      if (otpResponse.result === "false") {
        setOutput('OTP does not match');
        return;
      }

      const data = await getUserDetails({ phone });
      setUserObj(data);
      const redirect = localStorage.getItem('login_redirect');
      localStorage.setItem('useriid', data._id);
      localStorage.removeItem('validate-phone');

      if (!redirect) {
        window.location.href = '/';
      } else {
        window.location.href = '/' + redirect;
      }
    } catch (error) {
      console.error("Error verifying OTP or fetching user details:", error);
      setOutput('An error occurred. Please try again.');
    }
  };


  const regenerateOtpClick = async () => {
    const userId = localStorage.getItem('useriid');
    const userData = await getRegeneratedOtp({ userId: userObj._id });
    if (userData) {
      setUserObj(userData.data);
      setSendOtpMsg(userData.message);
      setCounter(20);
      setInterval(() => {
        setSendOtpMsg("");
      }, 20000);
    }

  };


  return (
    <div>
      {/*
     <section className="breadscrumb-section pt-0">
      <div className="container-fluid-lg">
        <div className="row">
          <div className="col-12">
            <div className="breadscrumb-contain">
              <h2>Log In</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="index-2.html">
                      <i className="fa fa-solid fa-house" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Log In</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      </section>
    */}
      <section className="log-in-section otp-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img src="../assets/images/inner-page/log-in.png" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="d-flex align-items-center justify-content-center h-100">
                <div className="log-in-box">
                  <div className="log-in-title">
                    <h3>Welcome To krazeal</h3>
                    <p>Please enter the OTP sent to {phone}</p>
                  </div>
                  <div className="col-12">
                    <font style={{ color: 'red' }}>{output}</font>
                    <form className="row g-4" onSubmit={handleSubmit}>
                      {/* Rest of your code */}
                      <font style={{ color: 'blue' }}>{lists.res}</font>
                      <div id="otp" className="inputs d-flex flex-row justify-content-center">
                        <input
                          type="text"
                          value={userOtp}
                          onChange={(e) => setUserOtp(e.target.value)}
                          className="text-center form-control rounded"
                          maxLength={4}
                          placeholder="Enter 4 digit OTP"
                        />
                      </div>
                      {/* Rest of your code */}
                      <div className="send-box pt-4"></div>
                      <button type="submit" className="btn btn-animation w-100 mt-3">
                        Verify OTP
                      </button>
                      <div>Not received OTP yet ?
                        {counter > 0 ?
                          counter > 9 ? `  00:${counter}` : `  00:0${counter}`
                          :
                          <a href="javascript:void(0)" onClick={regenerateOtpClick}>  Resend</a>
                        }
                      </div>
                      <div className="text-success text-bold" >{sendOtpMsg}</div>
                    </form>
                  </div>
                  {/* Rest of your code */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Rest of your code */}
    </div>
  );
}

export default Otp;
