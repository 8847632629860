import React, { useState, useEffect } from 'react'
import "./autocompleteSearch.css"
import {getProductsList} from "../utils/DatabaseAccess.js"
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; 
import $ from 'jquery';
window.$ = window.jQuery = $; 


const SuggestionsList = props => {

  const {
    suggestionsList,
    inputValue,
    onSelectSuggestion,
    displaySuggestions,
    selectedSuggestion
  } = props;

  if (inputValue && displaySuggestions) {
    if (suggestionsList.length > 0) {
      return (
        <ul className="suggestions-list">
          {suggestionsList.map((suggestion, index) => {
            const isSelected = selectedSuggestion === index;
            const classname = `suggestion ${isSelected ? "selected" : ""}`;
            return (
              <li
                key={index}
                className={classname}
                onClick={() => onSelectSuggestion(index)}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <></>;
    }
  }
  return <></>;
};

const Autocompletesearch = (props) => {
  const  navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState("");
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(0);
  const [displaySuggestions, setDisplaySuggestions] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [queryParam] = useSearchParams();
  const [search, setSearch] = React.useState(queryParam.get("q") || "");
  const ref = React.useRef();

  useEffect(() => {
    const input = document.querySelector("input");
    input.focus();
    input.select();
    setProductsList();
    setInputValue(search);
    console.log("search=",search);
  }, []); 

  const setProductsList = async() => {
      const  data = [];
      const res = await getProductsList(data);
      var results = [];
      if (res) {
        for (var key in res.data) {
          results.push(res.data[key]["product_name"]);
        };
      }
      setSuggestions(results);
  }
  const onChange = event => {
    const value = event.target.value;
    setInputValue(value);
    setSearch(value);
    const filteredSuggestions = suggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions.slice(0, 5));
    setDisplaySuggestions(true);
  };

  const onSelectSuggestion = index => {
    setSelectedSuggestion(index);
    setInputValue(filteredSuggestions[index]);
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
    let toLink = encodeURIComponent(filteredSuggestions[index]);
    mobileModalHide();
    props.setSearch(filteredSuggestions[index]);
    navigate("/search/"+toLink);
  }

  const mobileModalHide = () => {
    if ($('#searchWindow').length) { // Ensure the element exists
      $('#searchWindow').modal('hide');
    } else {
      console.error("Modal element #searchWindow not found");
    }
    
    props.setSearch(inputValue);
  };


  const searchManual = async () => {
    mobileModalHide();
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
    if(inputValue) {
        let toLink = encodeURIComponent(inputValue);
        navigate("/search/"+toLink);
        props.setSearch(inputValue);
    }
  }
  return (
    <>
      <div className="input-group search-display">
          <button type="button" className="btn btn-animation btn-md fw-bold mobile-only" onClick={mobileModalHide}><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
          <input type="text" class="form-control" id="searchBox" placeholder="Search product"
            ref={ref}
            onChange={onChange}
            value={inputValue}
            type="text"
            autocomplete="off"
            style={{borderRadius: "5px 0px 0px 5px"}}
          />
          <SuggestionsList
            inputValue={inputValue}
            selectedSuggestion={selectedSuggestion}
            onSelectSuggestion={onSelectSuggestion}
            displaySuggestions={displaySuggestions}
            suggestionsList={filteredSuggestions}
          />
          <button className="btn desktop-only" type="button" id="button-addon2" onClick={searchManual}>
          <svg xmlns="http://www.w3.org/2000/svg" width={4} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx={11} cy={11} r={8} /><line x1={21} y1={21} x2="16.65" y2="16.65" /></svg>
          </button>
      </div>
    </>
  );
};

export default Autocompletesearch;
