import React from 'react'
import Main from '../home/Main'
import Category from '../home/Category'
import HoverBox from '../home/HoverBox'

const Home = () => {
  return (
    <>
     <Category/>
     <Main/> 
     <HoverBox/>
    </>
  )
}

export default Home
