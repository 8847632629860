import React from 'react'

const HoverBox = () => {
  return (
    <>
           <section>
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="offer-box hover-effect bg-size blur-up lazyloaded" style={{backgroundImage: 'url("../assets/images/veg-2/banner/3.jpg")', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', display: 'block'}}>
                <img src="../assets/images/veg-2/banner/3.jpg" className="bg-img blur-up lazyload" alt="" style={{display: 'none'}} />
                <div className="offer-contain row">
                  <div className="col-md-5">
                    <div className="service-contain">
                      <div className="service-box col-lg-12">
                        <div className="service-image">
                          <img src="https://themes.pixelstrap.com/fastkart/assets/svg/product.svg" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="service-detail">
                          <h5>Easy Return</h5>
                        </div>
                      </div>
                      <br/>
                      <div className="service-box col-lg-12">
                        <div className="service-image">
                          <img src="https://themes.pixelstrap.com/fastkart/assets/svg/delivery.svg" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="service-detail">
                          <h5>Free Delivery For Order Over ₹500</h5>
                        </div>
                      </div>
                      <br/>
                      <div className="service-box col-lg-12">
                        <div className="service-image">
                          <img src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="service-detail">
                          <h5>Secure Payment</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="offer-detail">
                      <h2 className="text-dark">All Deals At Flat <span className="text-danger fw-bold">Discount
                        </span></h2>
                      <p className="text-content"><b>Deals till stock lasts !</b></p>
                    </div>
                  </div>
                  {/*
                  <div className="offer-timing">
                    <div className="time" id="clockdiv-1" data-hours={1} data-minutes={2} data-seconds={3}>
                      <ul>
                        <li>
                          <div className="counter">
                            <div className="days">14</div>
                          </div>
                        </li>
                        <li>
                          <div className="counter">
                            <div className="hours">23</div>
                          </div>
                        </li>
                        <li>
                          <div className="counter">
                            <div className="minutes">39</div>
                          </div>
                        </li>
                        <li>
                          <div className="counter">
                            <div className="seconds">55</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default HoverBox
