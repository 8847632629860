import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import Address from "../product/Address";
import Order from "../product/Order";
import {getOrderDetails,getCartList,getProductsPrice,getReturnProductPrice,getOrdersFromSummaryId } from "../utils/DatabaseAccess.js";
import Parser from 'html-react-parser';

function ReturnOrderlist(props) {
  
  const [cartList, setCartList] = useState([]);
  const [data, setData] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [lists, setLists] = useState([]);
  const [counts, setCounts] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [paymentNeeded, setPaymentNeeded] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponObj, setCouponObj] = useState(null);
  const [couponMsg, setCouponMsg] = useState("");
  const [deliveryDiscount, setDeliveryDiscount] = useState(0);
  const [deliveryRefund, setDeliveryRefund] = useState(0);
  const [couponError, setCouponError] = useState();
  const [couponSuccess, setCouponSuccess] = useState();
  const [actualPrice, setActualPrice] = useState(0);
  const [retOrderObj, setRetOrderObj] = useState(0);
  const [orderSummaryObj, setOrderSummaryObj] = useState();
  const [deliveryDiscountMsg, setDeliveryDiscountMsg] = useState();
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    ReturnOrderlistInit();
  }, []);

  const ReturnOrderlistInit = async () => {
    const userId = localStorage.getItem("useriid");
    var productsList = [];
    var priceDetails;
    if (props?.orderSummaryId && props?.orderId) {
      const data = {userId,
                    orderSummaryId:props?.orderSummaryId,
                    orderId:props?.orderId};
      var orderRes = await getOrderDetails(data);
      setRetOrderObj(orderRes.data.productInfo);
      productsList.push(orderRes.data.productInfo);
      priceDetails = await getReturnProductPrice(data);
    }
    setCartList(productsList);
    setTotalPrice(priceDetails.total_price);
    setTotalDiscount(priceDetails.total_discount);
    setCouponDiscount(priceDetails.coupon_discount);
    setDeliveryCharges(priceDetails.delivery_charge);
    setDeliveryDiscount(priceDetails.delivery_discount);
    setPaymentNeeded(priceDetails.payment_needed);
    setCouponObj(priceDetails.couponObj);
    setCouponMsg(priceDetails.coupon_msg);
    setCouponError(priceDetails.coupon_error_msg);
    setCouponSuccess(priceDetails.coupon_success_msg);
    setActualPrice(priceDetails.actual_price);
    const orderSummary = JSON.parse(priceDetails.orderSummaryObj);
    setOrderSummaryObj(orderSummary);
    setDeliveryDiscountMsg(priceDetails.delivery_msg);
    setTotalItems(priceDetails.total_items);
    props.setPaymentNeeded(priceDetails.payment_needed);
    setDeliveryRefund(priceDetails.delivery_refund);
  };

    return (
    <>
    <div className="col-lg-4">
      <div className="right-side-summery-box">
        <div className="summery-box-2">
          <div className="summery-header">
            <h3>Total Order Summery ({totalItems} items)</h3>
          </div>
          <ul className="summery-total">
            {orderSummaryObj?.coupon_discount > 0 &&
            <li>
              <h4>Coupon Discount ({couponObj?.coupon_code})</h4>
              <h4 className="price text-success">-₹{orderSummaryObj?.coupon_discount}</h4>
            </li>
            }
            {couponMsg &&
            <span className="text-success">({Parser(couponMsg+"")})</span>
            }
            <li>
              <h4>Delivery Charges</h4>
            {orderSummaryObj?.delivery_charge != 0 && (orderSummaryObj?.delivery_charge - orderSummaryObj?.delivery_discount) == 0 ?
              <h4 className="price text-success"><del>₹{orderSummaryObj?.delivery_charge}</del>&nbsp;(free)</h4>
            :
              <h4 className="price">
              {(orderSummaryObj?.delivery_charge - orderSummaryObj?.delivery_discount) == 0 ?
              <><s>{orderSummaryObj?.delivery_charge}</s> (free)</>
              :
              <>₹{orderSummaryObj?.delivery_charge - orderSummaryObj?.delivery_discount}</>
              }
              </h4>
            }
            </li>
            <li className="list-total theme-color">
              <h4 className="theme-color">Total Amount Paid</h4>
              <h4 className="price text-end">
              {orderSummaryObj?.total_price != orderSummaryObj?.payment_needed &&
              <><del>₹{orderSummaryObj?.total_price}</del> &nbsp;</>
              }
              ₹{orderSummaryObj?.payment_needed}</h4>

            </li>
          </ul>
        </div>
        <br/>
        <div className="summery-box-2">
          <div className="summery-header">
            <h3>Refund Summary (1 item)</h3>
          </div>
          <ul className="summery-contain">
            {cartList.map((list) => {
              return (
                <li>
                  <
                  img src={ list.product_image ? `${process.env.REACT_APP_DB_URL}/uploads/${list.product_image}` : `${process.env.REACT_APP_DB_URL}/uploads/${list.images[0]}`}
                    className="img-fluid blur-up lazyloaded checkout-image"
                  />
                  <h4>
                    {" "}
                    <span>{list.product_name}</span>
                  </h4>
                  <h4 className="price text-wrap">
                  <b>₹{list.sale_price}</b></h4>
                </li>
              );
            })}
          </ul>
          <ul className="summery-total">
            {couponError != "" &&
            <>
            <li>
              <h4>Coupon Discount</h4>
              <h4 className="price text-danger">(-) ₹{orderSummaryObj?.coupon_discount} </h4>
            </li>
              <h7 className="price text-danger">({Parser(couponError+"")}) </h7>
            </>
            }
            {deliveryRefund != 0 &&
            <>
            <li>
              <h4>Delivery Charges</h4>
              <h4 className="price">({deliveryRefund > 0 ? "+" : "-"}) ₹{Math.abs(deliveryRefund)}</h4>
            </li>
            {deliveryDiscountMsg &&
            <h7 className="price text-danger">({Parser(deliveryDiscountMsg+"")})</h7>
            }
            </>
            }
            <li className="list-total text-bold theme-color">
              <h4 className="text-bold theme-color">{props?.page === "order-cancel-process" ? "Total Refund" : "Total Payable"} </h4>
              <h4 className="price">{paymentNeeded < 0 ? <> <del>({paymentNeeded})</del>&nbsp;&#8377;0</> : <>&#8377;{paymentNeeded}</>}</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
    );
}

export default ReturnOrderlist;
