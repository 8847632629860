import { Link } from 'react-router-dom';
import {useNavigate,useParams } from 'react-router-dom';
import React,{ useState, useEffect }  from 'react'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import StarRating from "../product/StarRating";
import ProductLink from "../product/ProductLink";
import { useSearchParams } from "react-router-dom";
import {getProductsList, getProductsSearch,getProductsByCategory} from "../utils/DatabaseAccess.js";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { isDesktopView, urlDecode } from '../utils/Generic';

const Main = () => {
  const [loader, setLoading] =useState(true);
  const [lists, setLists]=useState([]);
  const [overallRating, setOverallRatings]=useState([]);
  const [queryParam] = useSearchParams();
  //const [search, setSearch]=useState(queryParam.get("q") || "");
  const  {search,categoryId,categoryName} = useParams();
  const  [isDesktop,setIsDesktop] = useState(isDesktopView());
  const navigate = useNavigate();

  useEffect(()=>{
    MainInit();
  },[search,categoryId]);

  const getOverallRating = (obj) => {
    var cnt = 0.0;
    var total_rating = 0.0;
    console.log(obj);
    if (obj.global_rating){
        if (obj.global_rating.amazon && obj.global_rating.amazon != 0) {
            cnt++;;
            total_rating += parseFloat(obj.global_rating.amazon);
        }
        if (obj.global_rating.flipkart && obj.global_rating.flipkart != 0) {
            cnt++;
            total_rating += parseFloat(obj.global_rating.flipkart);
        }
    }
    if (obj.rating){
        if (obj.rating && obj.rating != 0) {
            cnt++;
            total_rating += parseFloat(obj.rating);
        }
    }
    if (cnt !=0 && total_rating !=0) {
        total_rating = parseFloat(total_rating/cnt).toFixed(1);
    }
    return [true,true,false,false,false]
    return total_rating;
  }

  const MainInit = async () => {
    setLoading(true);
    const options ={
        headers: {
            "content-type" : "application/json; charset=utf-8",
            "Access-Control-Allow-Origin" : '*'
        }
    }
    let res = "";
    if (categoryId != null) {
        let data = {categoryId:categoryId};
        res = await getProductsByCategory(data);
    } else if (search != null) {
        var decodeSearch = decodeURIComponent(search);
        let data = {key:decodeSearch};
        res = await getProductsSearch(data);
    } else {
        res = await getProductsList({});
    }
    if(res){
      localStorage.setItem("token", res?.token);
      setLoading(false)
      setLists(res?.data);
      console.log("DATA=",res);
    } else{
      setLoading(false)
    }
  }

  return (
    <>
     <section className="product-section">
        <div className="container-fluid-lg">
          <div className="title title-flex-2">
            <div className="title">
              {search ?
              <h2>Search results</h2>
              :categoryId ? 
              <h2>{categoryName}</h2>
              :
              <h2>Current Deals</h2>
              }
              <span className="title-leaf">
                <svg className="icon-width">
                  <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                </svg>
              </span>
              {/*<p style={{marginTop: '-7px'}}>A virtual assistant collects the products from your list</p> */}
            </div>
          </div>
          <div className="tab-content" id="myTabContent" style={{marginTop: '-40px'}}>
            <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
              <div className="row g-8">
           { !loader ?
              lists.map((item) => {
               
               return(
                <div className="col-xxl-3 col-lg-3 col-md-4 col-6 wow fadeInUp" key={item.id} style={{visibility: 'visible', animationName: 'fadeInUp'}}>
                  <div className="product-box-4 ">
                    <div className="product-image">
                      <ProductLink obj={item} with_image={true} out_of_stock={item.instock > 0 ? false : true} />
                    </div>
                    <div className="product-detail">
                      
                      <ProductLink obj={item} with_name={true} />
                      <StarRating rating={item.rating} flipkart={item.global_rating?.flipkart} amazon={item.global_rating?.amazon} />
                      <h5 className="price ">₹{item.sale_price}<del>₹{item.price}</del>
                        <p className="offers ">{item.discount_percentage}% Off</p> 
                      </h5>
                    </div>
                  </div>
                </div>
                )
              })
            :
            <>
            {isDesktop &&
            <Grid container spacing={3} >
              <Grid item xs={3}>
                <Skeleton variant="rectangular" width={300} height={350} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="rectangular" width={300} height={350} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="rectangular" width={300} height={350} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="rectangular" width={300} height={350} />
              </Grid>
            </Grid>
            }
            {!isDesktop &&
            <Grid container spacing={3} >
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={170} height={250} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={170} height={250} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={170} height={250} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={170} height={250} />
              </Grid>
            </Grid>
            }
            </>
            }
             
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
