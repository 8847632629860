import 'font-awesome/css/font-awesome.min.css';
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

import Otp from './component/validation/Otp';
import PrivacyPolicy from './component/policies/PrivacyPolicy';
import TermOfUse from './component/policies/TermOfUse';
import React from "react-dom";
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import Header from './component/common/Header';
import Header2 from './component/common/Header2';
import Home from './component/home/Home';
import Payment from './component/payment/Payment';
import ShopTop from './component/shop-top/ShopTop';
import Compare from './component/compare/Compare';
import Cart from './component/carts/Cart';
import Product from './component/product/Product';
import CheckOut from './component/checkout/CheckOut';
import OrderSuccess from './component/order-success/OrderSuccess';
import OrderCancel from './component/order-success/OrderCancel';
import OrderTracking from './component/order-tracking/OrderTracking';
import User from './component/user/User';
import Login from './component/validation/Login';
import Register from './component/validation/Register';
import ForgotPassword from './component/validation/ForgotPassword';
import Wishlist from './component/wishlist/Wishlist';
import Contact from './component/contact/Contact';
import ShopLeftSidebar from './component/shop-left-sidebar/ShopLeftSidebar';
import About from './component/about/About';
import Footer2 from './component/common/Footer2';
import Blog from './component/blog/Blog';
import Faq from './component/faq/Faq';
import Search from './component/search/Search';
import ReturnPolicy from './component/return-policy/ReturnPolicy';
//import TermOfUse from './component/term-of-use/TermOfUse';
import BlogDetails from './component/blog/BlogDetails';
import ShopTopFilter from './component/shop-top/ShopTopFilter';
import UpdateUser from "./component/user/UpdateUser";
import Addaddress from "./component/product/Addaddress";
import Order from "./component/product/Order";
import Orderlist from './component/order-tracking/Orderlist';
import OrderCancelProcess from './component/order-tracking/OrderCancelProcess';
import ErrorPage from './component/errorHandling/ErrorPage';
import PdfViewer from './component/common/PdfViewer';
import DeleteSteps from './component/delete-account-steps/DeleteSteps';
// import Header2 from './component/common/Header2';

function App() {
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
  }
  if (window.location.pathname.startsWith("/openpdf")) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path='/openpdf/:pdf' element={<PdfViewer />} />
            <Route exact path="*" element={<ErrorPage />}></Route>
          </Routes>
        </BrowserRouter>
      </>
    );
  }
  return (

    <>
      <BrowserRouter>
        <Header2 />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/payment' element={<Payment />} />
          <Route exact path='/shop-top' element={<ShopTop />} />
          <Route exact path='/compare' element={<Compare />} />
          <Route exact path='/cart' element={<Cart />} />
          <Route exact path='/check-out' element={<CheckOut />} />
          <Route path='/product/:productId/:productName?/*' element={<Product />} />
          <Route exact path='/order-success' element={<OrderSuccess />} />
          <Route path='/order-cancel/:orderSummaryId?' element={<OrderCancel />} />
          <Route path='/order-failed/:orderSummaryId?' element={<OrderCancel />} />
          <Route exact path='/order-cancel-process' element={<OrderCancelProcess />} />
          <Route path='/order-tracking/:orderSummaryId/:orderId?' element={<OrderTracking />} />
          <Route path='/user/:navBar?' element={<User />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgot' element={<ForgotPassword />} />
          <Route exact path='/wishlist' element={<Wishlist />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/shop-left-sidebar' element={<ShopLeftSidebar />} />
          <Route exact path='/blog' element={<Blog />} />
          <Route exact path='/blog-details' element={<BlogDetails />} />
          <Route exact path='/about' element={<About />} />
          <Route path='/search/:search' element={<Search />} />
          <Route path='/category/:categoryId/:categoryName?/*' element={<Search />} />
          <Route exact path='/faq' element={<Faq />} />
          <Route exact path='/return-policy' element={<ReturnPolicy />} />
          <Route exact path='/term-of-use' element={<TermOfUse />} />
          <Route exact path='/shop-top-filter' element={<ShopTopFilter />} />
          <Route exact path='/update-user' element={<UpdateUser />} />
          <Route exact path="/addaddress" element={<Addaddress />}></Route>
          <Route exact path="/order" element={<Order />}></Route>
          <Route exact path="/otp" element={<Otp />}></Route>
          <Route exact path='/orderlist' element={<Orderlist />}></Route>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route exact path="/delete-steps" element={<DeleteSteps />}></Route>
          <Route exact path="*" element={<ErrorPage />}></Route>

        </Routes>
        <Footer2 />
      </BrowserRouter>
    </>
  );
}

export default App;
