import Address from "../product/Address";
import Addaddress from "../product/Addaddress";
import Orderlist from "../order-tracking/Orderlist";
import ReturnOrderlist from "../order-tracking/ReturnOrderlist";
import {returnOrder, cancelOrder, getAddressList, getPaymentUrl, createDbOrder, getCartList} from "../utils/DatabaseAccess.js";
import React from "react";
import { useState, useEffect, useRef } from "react";
import parse from 'html-react-parser';
import axios from "axios";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import { useForm } from "react-hook-form";
import {getUserDetails} from "../utils/DatabaseAccess.js";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { useSearchParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {sleep, scrollToTop } from "../utils/Generic";
import LoadingButton from '@mui/lab/LoadingButton';
import {getSummaryInfoFromId} from "../utils/DatabaseAccess.js"
import Rectangular from "../skelton/rectangular"

const OrderCancelProcess = () => {
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressList, setAddressList] = useState(null);
  const [ccavanueData, setCcavanueData] = useState("");
  const [frameHeight , setFrameHeight] = useState("600")
  const [paymentDisplay , setPaymentDisplay] = useState("none")
  const [addressDisplay , setAddressDisplay] = useState("block")
  const [addAddressDisplay , setAddAddressDisplay] = useState("none")
  const [userObj, setUserObj] = useState("");
  const [cartObjs, setCartObjs] = useState();
  const [paymentNeeded, setPaymentNeeded] = useState(0);
  const [queryParam] = useSearchParams();
  const [orderSummaryId, setOrderSummaryId] = useState(queryParam.get("summary_id") || "");
  const [orderId, setOrderId] = useState(queryParam.get("order_id") || "");
  const [type, setType] = useState(queryParam.get("type") || "");
  const [error, setError] = useState({});
  const [cancelingInprogress,setCancelingInprogress] = useState(false);
  const [summaryObj,setSummaryObj] = useState(null);
  const ref = React.useRef();
  const commentRef = React.useRef();
  const navigate = useNavigate();
  const [reason, setReason] = React.useState('Please select reason for cancellation(*)');
  useEffect(() => {
    OrderCancelProcessInit();
    scrollToTop();
  }, []);

  const OrderCancelProcessInit = async () => {
      const userId = localStorage.getItem("useriid");
      if(userId && orderSummaryId) {
        const data = {userId,orderSummaryId};
        if (!type || (type != "cancel" && type != "return" )) {
          navigate("/pageNotFound");
          return;
        }
        const summaryInfoRes = await getSummaryInfoFromId(data);
        if(!summaryInfoRes || (type == "cancel" && summaryInfoRes.status == "cancelled") || (type == "return" && summaryInfoRes.status == "return")) {
          navigate("/pageNotFound");
          return;
        }
        setSummaryObj(summaryInfoRes);
      } else {
          navigate("/pageNotFound");
          return;
      }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setReason(event.target.value);
  };

  const changeDisplay = async (e) => {
    setPaymentDisplay("none");
    setAddressDisplay("block");
  }
  const handleReasonSubmit = async (e) => {
    const userid = localStorage.getItem("useriid");
    e.preventDefault();
    var errorTemp = {};
    let errorFound = false;
    if(!commentRef.current.value) {
      errorTemp['comment'] = "! Please provide comment";
      errorFound = true;
    }
    if(reason == "Please select reason for cancellation(*)" || reason == "") {
      errorTemp['reason'] = "Please select a reason";
      errorFound = true;
    }
    setError(errorTemp);
    if(!errorFound) {
      setPaymentDisplay("block");
      setAddressDisplay("none");
    }
  };

  const orderReturnOrCancel = async (e) => {
      setCancelingInprogress(true);
      const userId = localStorage.getItem("useriid");
      var data = {orderSummaryId,userId,orderId,reason,
                  comments: commentRef.current.value
                 };
      if (type === "return") {
        const res = await returnOrder(data);
        sleep(1);
        setCancelingInprogress(false);
        navigate(`/order-tracking/${orderSummaryId}/${orderId}`);
      } else {
        const res = await cancelOrder(data);
        sleep(1);
        setCancelingInprogress(false);
        navigate(`/order-tracking/${orderSummaryId}/${orderId}`);
      }
  };
  const navgateToOrder = () => {
      navigate(`/order-tracking/${orderSummaryId}/${orderId}`);
  };

  return (
    <>
    {summaryObj?
      <>
      {/*
      <section className="cart-section section-b-space">
        <div className="container-fluid-lg">
                <div className="summery-contain text-center">
                  <ul>
                    <li><h1><svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 00 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
</svg></h1></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><h2 className="price p-1 theme-color">Your cart is empty!</h2></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><h4 className="price p-1">Explore our crazy deals and find something you like you</h4></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><Link to="/" >Continue shopping</Link></li>
                  </ul>
                </div>

        </div>
      </section>
      */}
      <section className="checkout-section-2 section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-lg-8">
              <div className="left-sidebar-checkout">
                <div className="checkout-detail-box">
                  <ul>
                    <li>
                      <div className="checkout-icon text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-1-circle" viewBox="0 0 16 16">
  <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z"/>
</svg>
                      </div>
                      <div className="checkout-box" style={{ display: paymentDisplay }}>
                        <div className="row">
                          <div className="col-sm-9">
                            <div className="checkout-title">
                              <h4>Easy {type == "return" ? "return" : "cancellation"}</h4>
                            </div>
                            <div className="checkout-detail" >
                              <b>Reason for {type == "return" ? "return" : "cancellation"}</b> : {reason} 
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button onClick={changeDisplay} className="btn btn-sm border border-danger">Change</button>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-box" style={{ display: addressDisplay }}>
                        <div className="checkout-title">
                          <h4>Easy Cancellation</h4>
                        </div>
                        <br/>
                        <div className="checkout-detail" >
                          <form onSubmit={handleReasonSubmit} >
                          <div className="row g-4" >
                            <NativeSelect
                                      onChange={handleChange}
                                    >
                              <option value="" >Please select reason for cancellation(*)</option>
                              <option value="Price of the product has now decreased">Price of the product has now decreased</option>
                              <option value="I'm worried about the ratings/reviews">I'm worried about the ratings/reviews</option>
                              <option value="I want to change the size/color/type">I want to change the size/color/type</option>
                              <option value="I was hoping for a shorter delivery time">I was hoping for a shorter delivery time</option>
                              <option value="I want to change the contact details">I want to change the contact details</option>
                              <option value="My reason is not listed here">My reason is not listed here</option>
                            </NativeSelect>
                            <div className="text-danger" ><b>{error.reason}</b></div>
                            <div class="form-group purple-border">
                              <label for="commentId">Comments for cancellation(*)</label>
                              <textarea class="form-control" id="commentId" ref={commentRef} rows="3"  placeholder="eg. Item not needed" maxlength="500" ></textarea>
                            </div>
                            <div className="text-danger" ><b>{error.comment}</b></div>

                            </div>
                            <div className="row g-4">
                              <div className="col-md-12">
                                <br/>
                                <button type="submit" name="confirm-address" className="btn btn-sm border border-danger end-0">Continue</button>
                              </div>
                            </div>
                            </form>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="checkout-icon text-success">
                          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-2-circle" viewBox="0 0 16 16">
  <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306"/>
</svg>
                        </div>
                        <div className="checkout-box">
                          <div className="checkout-title">
                            <h4>{type == "return" ? "Return" : "Cancellation"} Request Confirmation</h4>
                          </div>
                          <div className="checkout-detail" style={{ display: paymentDisplay }}>
                            <div className="accordion accordion-flush custom-accordion" id="accordionFlushExample">
                              <div className="col-md-12">
                              {paymentNeeded > 0 &&
                              <> &#8377;{paymentNeeded} refund will be transferred within 1 business day (Bank holidays not included). Do you want to continue ?</>}
                              {paymentNeeded <= 0 &&
                                <>Accoding to the calculation no refund (&#8377;0) will be applicable. Do you still want to continue ?</>}
                              </div>
                              <div class="btn-group" role="group" aria-label="Basic example">
                              <LoadingButton
                                color="warning"
                                onClick={orderReturnOrCancel}
                                loading={cancelingInprogress}
                                loadingPosition="start"
                                variant="outlined"
                                className="btn btn-md bg-danger btn-danger text-white"
                              >Yes, Please Cancel</LoadingButton>&nbsp;
                              <button type="button" onClick={navgateToOrder} className="btn btn-sm border border-danger ">No Cancellation</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {type === "return" ?
            <ReturnOrderlist orderSummaryId={orderSummaryId} orderId={orderId} page="order-cancel-process" setPaymentNeeded={setPaymentNeeded} type={type}/> 
            :
            <Orderlist orderSummaryId={orderSummaryId} orderId={orderId} page="order-cancel-process" setPaymentNeeded={setPaymentNeeded} type={type}/> }
          </div>
        </div>
      </section>
      </>
      :
      <>
      <section className="checkout-section-2 section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-lg-12">
              <div className="left-sidebar-checkout">
                <div className="checkout-detail-box">
                  <Rectangular height={"1060"} width={"1060"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    }

    </>
    );
};

export default OrderCancelProcess;
