import { Link } from 'react-router-dom';
import React,{useState,useEffect} from 'react'
import {getCartList, getUserDetails} from "../utils/DatabaseAccess.js";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/Redux';


const Header = (props) => {
  const [userObj, setUserObj] = useState("")
  const [cartCnt, setCartCnt] = useState(0)
  useEffect(()=>{
    updateLoginInfo();
    updateCart();
  }, []);
  const updateLoginInfo=async()=> {
    const userid = localStorage.getItem("useriid");
    if (userid != null) {
      const userData = await getUserDetails({userId:userid});
      setUserObj(userData);
    } else {
      setUserObj(null);
    }
  };
  const updateCart=()=> {
    const userid = localStorage.getItem("useriid");
    var cart_cnt = localStorage.getItem("cartcnt");
    if (cart_cnt == null && userid) { 
        (async () => {
            const data = JSON.stringify({
              "userId": userid,    
            });
            const resData = await getCartList(data);
            console.log(resData.list.length);
            cart_cnt = resData.list.length;
            props.setCartCount(cart_cnt);
            setCartCnt(cart_cnt)
            localStorage.setItem("cartcnt", cart_cnt);
        })();
        return;
    } else if(cart_cnt == null) {
        cart_cnt = 0;
    }
    props.setCartCount(cart_cnt);
    localStorage.setItem("cartcnt", cart_cnt);
  }

  const removeToken = () => {
    localStorage.removeItem('useriid');
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("password");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.setItem("cartcnt", 0);
    props.onResetClick()
};

  return (
    <>
      <header className="header-2" style={{background: '#000c6a'}}>
        <div className="top-nav top-header sticky-header sticky-header-3" style={{background: '#000c6a'}}>
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">
                  <button className="navbar-toggler d-xl-none d-block p-0 me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
                    <span className="navbar-toggler-icon">
                      <i className="iconly-Category icli theme-color" />
                    </span>
                  </button>
                  <Link to='/' className="web-logo nav-logo">
                    <img src="../assets/images/logo/logoblue.png" className="img-fluid blur-up lazyloaded" alt="" />
                  </Link>
                  <div className="top-search-full">
                    <form action="#">
                        <div  className="input-group">
                           <input  className="form-control rounded " type="search" placeholder="Search for products" />
                           <span  className="input-group-append">
                              <button  className="btn bg-white border border-start-0 ms-n10 rounded-0 rounded-end" type="button">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"  className="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                 </svg>
                              </button>
                           </span>
                        </div>
                     </form>
                  </div>
                  {userObj != null ?
                    <div className="rightside-menu">
                      <div className="dropdown-dollar">
                        <div className="dropdown">
                         {/*<p style={{color:'white'}}>{localStorage.getItem("fname")}</p> */}
                          <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{userObj.fname ? userObj.fname : userObj.phone}</span> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down" style={{ color: 'white' }}><polyline points="6 9 12 15 18 9" /></svg>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                              <Link id="eng" className="dropdown-item" to="/user">My Profile</Link>
                            </li>
                            <li>
                              <Link id="hin" className="dropdown-item" to="/user">Orders</Link>
                            </li>
                            {/*
                            <li>
                            <Link id="guj" className="dropdown-item" to="/user">Coupons</Link>
                            </li>
                            */}
                            <li>
                            <Link id="guj" className="dropdown-item" onClick={removeToken} to="/login">Logout</Link>
                            </li>
                          </ul>
                        </div>
                    
                      </div>
                      <div className="option-list">
                        <ul>
                     
                       
                          {/*
                          <li>
                            <Link to="/compare" className="header-icon">
                              <small className="badge-number">2</small>
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" /><path d="M13.73 21a2 2 0 0 1-3.46 0" /></svg>
                            </Link>
                          </li>
                          <li className="onhover-dropdown">
                            <Link to="/wishlist" className="header-icon bag-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" /></svg>
                            </Link>
                          </li>
                          */}
                          <li className="onhover-dropdown">
                            <Link to="/cart" className="header-icon bag-icon">
                              <small className="badge-number">{props.count}</small>
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx={9} cy={21} r={1} /><circle cx={20} cy={21} r={1} /><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
                            </Link>
                            {/* <div className="onhover-div">
                              <ul className="cart-list">
                                <li>
                                  <div className="drop-cart">
                                    <a href="/product" className="drop-image">
                                      <img src="../assets/images/vegetable/product/1.png" className="blur-up lazyloaded" alt="" />
                                    </a>
                                    <div className="drop-contain">
                                      <a href="/product">
                                        <h5>Fantasy Crunchy Choco Chip Cookies</h5>
                                      </a>
                                      <h6><span>1 x</span> $80.58</h6>
                                      <button className="close-button">
                                        <i className="fa fa-xmark" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="drop-cart">
                                    <a href="/product" className="drop-image">
                                      <img src="../assets/images/vegetable/product/2.png" className="blur-up lazyloaded" alt="" />
                                    </a>
                                    <div className="drop-contain">
                                      <a href="/product">
                                        <h5>Peanut Butter Bite Premium Butter Cookies 600 g
                                        </h5>
                                      </a>
                                      <h6><span>1 x</span> $25.68</h6>
                                      <button className="close-button">
                                        <i className="fa fa-xmark" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div className="price-box">
                                <h5>Price :</h5>
                                <h4 className="theme-color fw-bold">$106.58</h4>
                              </div>
                              <div className="button-group">
                                <a href="/cart" className="btn btn-sm cart-button">View Cart</a>
                                <a href="/check-out" className="btn btn-sm cart-button theme-bg-color
                                                    text-white">Checkout</a>
                              </div>
                            </div> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                    :
                    <div className="rightside-menu">
                    <div className="dropdown-dollar">
                      <div className="dropdown">
                        
                      <div className="option-list">
                        <ul>
                     
                       
                          <li>
                            <Link to="/cart" className="header-icon">
                              <small className="badge-number">{props.count}</small>
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx={9} cy={21} r={1} /><circle cx={20} cy={21} r={1} /><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
                            </Link>
                          </li>
                          {/*
                          <li className="onhover-dropdown">
                            <Link to="/wishlist" className="header-icon bag-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" /></svg>
                            </Link>
                          </li>
                          */}
                          <li className="onhover-dropdown">
                            <Link to="/cart" className="header-icon bag-icon">
                              <small className="badge-number">2</small>
                              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx={9} cy={21} r={1} /><circle cx={20} cy={21} r={1} /><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
                            </Link>
                            {/* <div className="onhover-div">
                              <ul className="cart-list">
                                <li>
                                  <div className="drop-cart">
                                    <a href="/product" className="drop-image">
                                      <img src="../assets/images/vegetable/product/1.png" className="blur-up lazyloaded" alt="" />
                                    </a>
                                    <div className="drop-contain">
                                      <a href="/product">
                                        <h5>Fantasy Crunchy Choco Chip Cookies</h5>
                                      </a>
                                      <h6><span>1 x</span> $80.58</h6>
                                      <button className="close-button">
                                        <i className="fa fa-xmark" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="drop-cart">
                                    <a href="/product" className="drop-image">
                                      <img src="../assets/images/vegetable/product/2.png" className="blur-up lazyloaded" alt="" />
                                    </a>
                                    <div className="drop-contain">
                                      <a href="/product">
                                        <h5>Peanut Butter Bite Premium Butter Cookies 600 g
                                        </h5>
                                      </a>
                                      <h6><span>1 x</span> $25.68</h6>
                                      <button className="close-button">
                                        <i className="fa fa-xmark" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div className="price-box">
                                <h5>Price :</h5>
                                <h4 className="theme-color fw-bold">$106.58</h4>
                              </div>
                              <div className="button-group">
                                <a href="/cart" className="btn btn-sm cart-button">View Cart</a>
                                <a href="/check-out" className="btn btn-sm cart-button theme-bg-color
                                                    text-white">Checkout</a>
                              </div>
                            </div> */}
                          </li>
                          <li/>
                          <li>
                            <Link to="/login" className="header-icon bag-icon">
                              {/*<button className='btn btn-denger'>Login/Signup</button>*/}
                               <img src="../assets/images/logo/profile.svg" className="img-fluid blur-up lazyloaded" alt="" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      </div>
                  
                    </div>
                    
                  </div>
              }
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </header>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
