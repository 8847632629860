import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Orderlist from "./Orderlist";
import Order from "../product/Order";
import {  useNavigate } from 'react-router-dom';

function Ordersummery(props) {
  const [lists, setLists] = useState([]);
  const [data, setData] = useState([]);
  const [summaryId, setSummaryId] = useState("");
  const [userid, setUserid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = async () => {
    const local_userid = localStorage.getItem("useriid");
    setUserid(local_userid);
    const amount = props.price;

    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const orderSummaryData = JSON.stringify({
      userId: local_userid,
      total_price: amount,
    });

    try {
      const orderSummaryResponse = await axios.post(
        process.env.REACT_APP_DB_URL+"/api/order_summery",
        orderSummaryData,
        
        options
      );

      const summaryData = orderSummaryResponse.data;

      const summaryId = summaryData.data._id;
      const summaryamount = summaryData.data.total_price;
       

      setLists(summaryData.data);
      setSummaryId(summaryId);

      localStorage.setItem("summaryId", summaryId); // Store summaryId in localStorage
      localStorage.setItem("total_price", summaryamount); // Store total_price in localStorage
    } catch (err) {
      console.error(err);
    }
  };
  const handleCheckoutClick = () => {
      if (userid == null) {
          localStorage.setItem("login_redirect","check-out");
          navigate("/login");
      } else {
          navigate("/check-out");
      }
  }

  return (
      <button
        className="btn text-white fw-bold"
        type="submit"
        onClick={handleCheckoutClick}
      >
        Check Out
      </button>
  );
}

export default Ordersummery;
