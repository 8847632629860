import React from 'react'
import Header2 from '../common/Header2'

const Contact = () => {
  return (
    <>
    {/*
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Contact Us</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">
                        <i className="fa fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}
      <section className="contact-box-section">
        <div className="container-fluid-lg">
          <div className="row g-lg-5 g-3">
            <div className="col-lg-6">
              <div className="left-sidebar-box">
                <div className="row">
                  {/*
                  <div className="col-xl-12">
                    <div className="contact-image">
                      <img src="../assets/images/inner-page/contact-us.png" className="img-fluid blur-up lazyloaded" alt="" />
                    </div>
                  </div>
                  */}
                  <div className="col-xl-12">
                    <div className="contact-title">
                      <h3>Contact Us</h3>
                    </div>
                    <div className="contact-detail">
                      <div className="row g-4">
                        <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa fa-phone" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Phone</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>(+1) 618 190 496</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa fa-envelope" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Email</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>geweto9420@chokxus.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa fa-location-dot" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>London Office</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>Cruce Casa de Postas 29</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa fa-building" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Bournemouth Office</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>Visitación de la Encina 22</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="title d-xxl-none d-block">
                <h2>Contact Us</h2>
              </div>
              <div className="right-sidebar-box">
                <div className="row">
                  {/*
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label htmlFor="exampleFormControlInput" className="form-label">Name</label>
                      <div className="custom-input">
                        <input type="text" className="form-control" id="exampleFormControlInput" placeholder="Enter Name" />
                        <i className="fa fa-user" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Last Name</label>
                      <div className="custom-input">
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Last Name" />
                        <i className="fa fa-user" />
                      </div>
                    </div>
                  </div>
                  */}
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label htmlFor="exampleFormControlInput2" className="form-label">Email Address</label>
                      <div className="custom-input">
                        <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="Enter Email Address" />
                        <i className="fa fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label htmlFor="exampleFormControlInput3" className="form-label">Phone Number</label>
                      <div className="custom-input">
                        <input type="tel" className="form-control" id="exampleFormControlInput3" placeholder="Enter Your Phone Number" maxLength={10} oninput="javascript: if (this.value.length > this.maxLength) this.value =
                                            this.value.slice(0, this.maxLength);" />
                        <i className="fa fa-mobile-screen-button" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label htmlFor="exampleFormControlTextarea" className="form-label">Message</label>
                      <div className="custom-textarea">
                        <textarea className="form-control" id="exampleFormControlTextarea" placeholder="Enter Your Message" rows={3} defaultValue={""} />
                        <i className="fa fa-message" />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-animation btn-md fw-bold ms-auto">Send Message</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="map-section">
        <div className="container-fluid p-0">
          <div className="map-box">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.26563315962!2d77.6562314!3d12.9676024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13f9499e6187%3A0x8958f7b3be2a520d!2sInnovative%20Barter%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1708024507318!5m2!1sen!2sin" style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
