import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import Address from "../product/Address";
import Order from "../product/Order";
import {getOrderDetails,getCartList,getProductsPrice,getReturnProductPrice,getOrdersFromSummaryId } from "../utils/DatabaseAccess.js";
function Orderlist(props) {
  
  const [cartList, setCartList] = useState([]);
  const [data, setData] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [deliveryCharges, setDeliveryCharges] = useState();
  const [lists, setLists] = useState([]);
  const [counts, setCounts] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [paymentNeeded, setPaymentNeeded] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponCode, setCouponCode] = useState(0);
  const [deliveryDiscount, setDeliveryDiscount] = useState(0);
  const [couponError, setCouponError] = useState();
  const [couponSuccess, setCouponSuccess] = useState();
  const [actualPrice, setActualPrice] = useState(0);

  useEffect(() => {
    OrderlistInit();
  }, []);

  const OrderlistInit = async () => {
    const userId = localStorage.getItem("useriid");
    const data = {userId,nonDisplayProduct:false};
    var productsList = [];
    var priceDetails;
    if (props?.type == "cancel") {
      // redirecting from cancel order page
      if (props?.orderSummaryId) {
        data["orderSummaryId"] = props.orderSummaryId;
        var lists = await getOrdersFromSummaryId(data);
        lists.map((list) => {
          productsList.push(list.productInfo);
        });
        priceDetails = await getProductsPrice(data);
      }
    } else {
      // redirecting from checkout page
      var temp = await getCartList(data);
      temp.list.map((list) => {
        productsList.push(list.productId);
      });
      priceDetails = await getProductsPrice({userId});
    }
    setCartList(productsList);
    setTotalPrice(priceDetails.total_price);
    setTotalDiscount(priceDetails.total_discount);
    setCouponDiscount(priceDetails.coupon_discount);
    setDeliveryCharges(priceDetails.delivery_charge);
    setDeliveryDiscount(priceDetails.delivery_discount);
    setPaymentNeeded(priceDetails.payment_needed);
    setCouponCode(priceDetails.coupon_code);
    setCouponError(priceDetails.coupon_error_msg);
    setCouponSuccess(priceDetails.coupon_success_msg);
    setActualPrice(priceDetails.actual_price);
    if (props?.page === "order-cancel-process")
      props.setPaymentNeeded(priceDetails.payment_needed);
  };
  

    return (
    <>
    <div className="col-lg-4">
      <div className="right-side-summery-box">
        <div className="summery-box-2" style={props?.priceDisplay && props?.priceDisplay == "none" ? {display:"none"} : {display:"block"} } >
          <div className="summery-header">
            <h3>Order Summery</h3>
          </div>
          <ul className="summery-contain">
            {cartList.map((list) => {
              return (
                <li>
                  <
                  img src={ list.product_image ? `${process.env.REACT_APP_DB_URL}/uploads/${list.product_image}` : `${process.env.REACT_APP_DB_URL}/uploads/${list.images[0]}`}
                    className="img-fluid blur-up lazyloaded checkout-image"
                  />
                  <h4>
                    {" "}
                    <span>{list.product_name}</span>
                  </h4>
                  <h4 className="price text-wrap"><del>₹{list.price}</del>
                  <br/>
                  <b>₹{list.sale_price}</b></h4>
                </li>
              );
            })}
          </ul>
          <ul className="summery-total">
            <li>
              <h4>Price ({cartList.length} items)</h4>
              <h4 className="price">₹{totalPrice}</h4>
            </li>
            {/*
            <li>
              <h4>Krazeal discount</h4>
              <h4 className="price text-success">(-) ₹{actualPrice - totalPrice}</h4>
            </li>
            */}
            {couponDiscount > 0 &&
            <li>
              <h4>Coupon Discount</h4>
              <h4 className={couponDiscount > 0 ? "price text-success" : "price" }>(-) ₹{couponDiscount}</h4>
            </li>
            }
            <li>
              <h4>Delivery Charges</h4>
              {deliveryDiscount > 0 ?
              <h4 className={deliveryDiscount > 0 ? "price text-success" : "price" }><del>₹{deliveryCharges}</del>&nbsp;({ deliveryCharges - deliveryDiscount === 0 ? "free" : (deliveryCharges - deliveryDiscount)})</h4>
              :
              <h4 className="price text-end">(+) ₹{deliveryCharges}</h4>
              }
            </li>
            <li className="list-total text-bold theme-color">
              <h4 className="text-bold theme-color">{props?.page === "order-cancel-process" ? "Total Refund" : "Total Payable"} </h4>
              <h4 className="price">₹{paymentNeeded}</h4>
            </li>
            <li>
              <h5 className="price text-success text-bold">Your total saving {props?.page === "order-cancel-process" && "was"} on this order ₹{actualPrice - paymentNeeded} </h5>
            </li>
          </ul>
        </div>
        {/*
        <Link to="/order-success">
          <Order />
        </Link>
        */}
      </div>
    </div>
    </>
    );
}

export default Orderlist;
