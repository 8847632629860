import React from 'react'

const Alert = (props) => {
  return (
    <>
    <div className="modal fade theme-modal" id={props?.alertId} role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog"  >
      <div className={"modal-dialog modal-dialog-centered modal-"+props.alertSize}>
        <div className="modal-content">
          <div className="modal-body">
            <h4>{props?.msg}</h4>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-animation btn-sm fw-bold" data-bs-dismiss="modal">{props.cancelBtn}</button>
            <button type="button" onClick={props.func} data-bs-dismiss="modal" className="btn theme-bg-color btn-sm fw-bold text-light">{props.confirmBtn}</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
};

export default Alert
