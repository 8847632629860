import React from 'react'
import {FaFacebook, FaInstagram, FaPinterestP, FaTwitter,} from "react-icons/fa"
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/Redux';
import { Link } from 'react-router-dom';

const Footer2 = (props) => {
  return (
    <>
        <footer className="section-t-space">
        <div className="container-fluid-lg">
          {/*
          <div className="service-section">
            <div className="row g-3">
              <div className="col-12">
                <div className="service-contain">
                  <div className="service-box">
                    <div className="service-image">
                      <img src="https://themes.pixelstrap.com/fastkart/assets/svg/product.svg" className="blur-up lazyloaded" alt="" />
                    </div>
                    <div className="service-detail">
                      <h5>30 Days Warranty</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img src="https://themes.pixelstrap.com/fastkart/assets/svg/delivery.svg" className="blur-up lazyloaded" alt="" />
                    </div>
                    <div className="service-detail">
                      <h5>Free Delivery For Order Over ₹50</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg" className="blur-up lazyloaded" alt="" />
                    </div>
                    <div className="service-detail">
                      <h5>Daily Mega Discounts</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg" className="blur-up lazyloaded" alt="" />
                    </div>
                    <div className="service-detail">
                      <h5>Secure Payment</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
          <div className="main-footer section-b-space section-t-space desktop-only">
            <div className="row g-md-4 g-3">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-logo">
                  <div className="theme-logo">
                    <a href="/">
                      <img src="../assets/images/logo/1.png" className="blur-up lazyloaded" alt="" />
                    </a>
                  </div>
                  <div className="footer-logo-contain">
                    <p>
                      Deals among different product categories at unbeatable price offer with a pleasant consumer experience. A e-commerce model for the deal hunters to discover astonishing deals and offers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-3">
                <div className="footer-title">
                  <h4>Useful Links</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    <li>
                      <a href="/user/order" className="text-content">Your Orders</a>
                    </li>
                    <li>
                      <a href="/user" className="text-content">Your Account</a>
                    </li>
                    <li>
                      <a href="/about" className="text-content">About Us</a>
                    </li>
                    <li>
                      <a href="/faq" className="text-content">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-3">
                <div className="footer-title">
                  <h4>Address</h4>
                </div>
                <div className="footer-contact">
                  <ul>
                    <li>
                      <div className="footer-number">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                        <div className="contact-number">
                          <h6 className="text-content">Deal smith India Private Limited</h6>
                          <h5>
                          No P3 & P4 First Floor,<br/>
                          Jeevan Bhima Nagar,<br/>
                          HAL 3rd Stage Bangalore,<br/>
                          pincode - 560075<br/>
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-3">
                <div className="footer-title">
                  <h4>Contact Us</h4>
                </div>
                <div className="footer-contact">
                  <ul>
                    <li>
                      <div className="footer-number">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                        <div className="contact-number">
                          <h6 className="text-content">Hotline 24/7 :</h6>
                          <h5>+91 988 086 8943</h5>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="footer-number">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                        <div className="contact-number">
                          <h6 className="text-content">Email Address :</h6>
                          <h5>customer.support@krazeal.com</h5>
                        </div>
                      </div>
                    </li>
                    {/*
                    <li className="social-app">
                      <h5 className="mb-2 text-content">Download App :</h5>
                      <ul>
                        <li className="mb-0">
                          <a href="https://play.google.com/store/apps" target="_blank">
                            <img src="https://themes.pixelstrap.com/fastkart/assets/images/playstore.svg" className="blur-up lazyloaded" alt="" />
                          </a>
                        </li>
                        <li className="mb-0">
                          <a href="https://www.apple.com/in/app-store/" target="_blank">
                            <img src="https://themes.pixelstrap.com/fastkart/assets/images/appstore.svg" className="blur-up lazyloaded" alt="" />
                          </a>
                        </li>
                      </ul>
                    </li>
                    */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-footer section-small-space">
            <div className="reserve">
              <h6 className="text-content">©{(new Date()).getFullYear()} Krazeal All rights reserved</h6>
            </div>
          </div>
        </div>
      </footer>
      <section id="footer">
        <div class="container">
          <div class="row">
            <div class="container text-center">
              <div class="row">
                <div class="col">
                  <div class="icon-box">
                    <Link to="/">
                     <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>  
                     <p>Home</p>
                   </Link>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                <div class="col">
                  <div class="icon-box btn p-0 position-relative header-wishlist">
                    <Link to="/user/notifications">
                       <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
                       {props?.notification_count && props.notification_count > 0 &&
                        <span class="position-absolute top-0 start-100 translate-middle badge">0<span class="visually-hidden">unread messages</span></span>}
                     <p>Notifications</p>
                   </Link>
                  </div>
                </div>
                &nbsp;
                &nbsp;
                &nbsp;
                <div class="col">
                  <div class="icon-box">
                    <Link to="/user">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                      <p>Account</p>
                    </Link>
                  </div>
                </div>
                <div class="col">
                  <div class="icon-box btn p-0 position-relative header-wishlist">
                    <Link to="/cart">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                     {props?.count && props.count > 0 &&
                     <span class="position-absolute top-0 start-100 translate-middle badge">{props.count}<span class="visually-hidden">unread messages</span></span>
                     }
                     <p>Cart</p>
                     </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer2);
