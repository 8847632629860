import { Link,useParams,useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useState  , useEffect} from 'react';
import { addToCart } from '../utils/DatabaseAccess';
import { sleep } from '../utils/Generic';
import { useSearchParams } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

function AddToCart(props) {
  const [queryParam] = useSearchParams();
  const [addingToCart, setAddingToCart]=useState(false);
  const  {productId,productName} = useParams();
  const navigate = useNavigate();
  const AddToCartInit = async () => {
      const  userId = localStorage.getItem('useriid');
      //const counters=props.count
      const prices=props.price
      const data = {
        productId,
        userId,
        // "color": "redd",
        //  "size": "full",
        "qty": 1,
      };
      setAddingToCart(true);
      const res = await addToCart(data);
      if(userId == null || !userId) {
        localStorage.setItem("login_redirect","cart");
        navigate("/login");
      } else {
        sleep(0.5);
        setAddingToCart(false);
        navigate("/cart");
      }
  }
     return (
        <>
          {props?.out_of_stock ?
          <>
          <div className="row">
          <h2>Currently Unavailable</h2>
          </div>
          </>
          :
          <>
          <div className="row">
            <LoadingButton 
                color="success"
                onClick={AddToCartInit} 
                loading={addingToCart} 
                loadingPosition="start"
                variant="outlined"
                className="row btn btn-md btn-primary text-white w-190"
                style={ props?.display === true ? {display : "block",borderRadius:50}: {display : "none" } }
            >Add to Cart</LoadingButton> 
          </div>
          </>
          }
        </>
     );
}

export default AddToCart;
