const Payment = () => {
  return (
      <form method="POST" action={process.env.REACT_APP_DB_URL + "/ccavRequestHandler"}>
        <table width="40%" height={100} border={1} align="center">
          <caption>
            <font size={4} color="blue"><b>Integration Kit</b></font>
          </caption>
        </table>
        <table width="40%" height={100} border={1} align="center">
          <tbody><tr>
              <td>Parameter Name:</td>
              <td>Parameter Value:</td>
            </tr>
            <tr>
              <td colSpan={2}>Compulsory information</td>
            </tr>
            <tr>
              <td>Merchant Id</td>
              <td><input type="text" name="merchant_id" defaultValue={2108374} /> </td>
            </tr>
            <tr>
              <td>Order Id</td>
              <td><input type="text" name="order_id" defaultValue={12345} /></td>
            </tr>
            <tr>
              <td>Currency</td>
              <td><input type="text" name="currency" defaultValue="INR" /></td>
            </tr>
            <tr>
              <td>Amount</td>
              <td><input type="text" name="amount" defaultValue={1.00} /></td>
            </tr>
            <tr>
              <td>Redirect URL</td>
              <td><input type="text" name="redirect_url" defaultValue="http://127.0.0.1:3002/ccavResponseHandler" />
              </td>
            </tr>
            <tr>
              <td>Cancel URL</td>
              <td><input type="text" name="cancel_url" defaultValue="http://127.0.0.1:3002/ccavResponseHandler" />
              </td>
            </tr>
            <tr>
              <td>Language</td>
              <td><input type="text" name="language" defaultValue="EN" /></td>
            </tr>
            <tr>
              <td colSpan={2}>Billing information(optional):</td>
            </tr>
            <tr>
              <td>Billing Name</td>
              <td><input type="text" name="billing_name" defaultValue="Peter" /></td>
            </tr>
            <tr>
              <td>Billing Address:</td>
              <td><input type="text" name="billing_address" defaultValue="Santacruz" /></td>
            </tr>
            <tr>
              <td>Billing City:</td>
              <td><input type="text" name="billing_city" defaultValue="Mumbai" /></td>
            </tr>
            <tr>
              <td>Billing State:</td>
              <td><input type="text" name="billing_state" defaultValue="MH" /></td>
            </tr>
            <tr>
              <td>Billing Zip:</td>
              <td><input type="text" name="billing_zip" defaultValue={400054} /></td>
            </tr>
            <tr>
              <td>Billing Country:</td>
              <td><input type="text" name="billing_country" defaultValue="India" />
              </td>
            </tr>
            <tr>
              <td>Billing Tel:</td>
              <td><input type="text" name="billing_tel" defaultValue={9876543210} />
              </td>
            </tr>
            <tr>
              <td>Billing Email:</td>
              <td><input type="text" name="billing_email" defaultValue="testing@domain.com" /></td>
            </tr>
            <tr>
              <td />
              <td><input type="submit" defaultValue="test Checkout" /></td>
            </tr>
          </tbody></table>
        <input type="hidden" name="integration_type" defaultValue="iframe_normal" />
      </form>
  );
};
export default Payment;
