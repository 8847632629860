import { useState , useEffect } from 'react';
import Addaddress from "../product/Addaddress.js";
import {deleteAddress, getAddressList} from "../utils/DatabaseAccess";
import {sleep} from "../utils/Generic";
import Alert from "../common/Alert.js";
import $ from 'jquery'; 
window.$ = window.jQuery = $; 

function Address2() {
    const [addressList, setAddressList]=useState([]);
    const [userId, setUserId]=useState();
    const [effectTrigger, setEffectTrigger]=useState(true);
    useEffect(() => {
        if(effectTrigger){
            getHomeData();
            setEffectTrigger(false);
        }
    }, [addressList,effectTrigger]);

  const setState =  () => {
    setEffectTrigger(true);
    console.log("HERE setState Address2");
  }
  const resetState = () => {
    sleep(0.1);
    setEffectTrigger(false);
    alert("HERE resetState Address2",effectTrigger);
  }


    const getHomeData = async () => {
        const userid = localStorage.getItem('useriid');
        const userAddressList = await getAddressList({"userId":userid});
        setAddressList(userAddressList);        
        setUserId(userid);
    };

    const deleteAddressConfirm = async (addressId) => {
        $('#deleteConfirm'+addressId).modal('show');
    }
    const deleteAddressClick = async (addressId) => {
        setEffectTrigger(true);
        const userAddressList = await deleteAddress({userId,addressId});
        setAddressList(userAddressList);        
        sleep(0.1);
        setEffectTrigger(false);
    }
    const editAddressClick = async (addressId) => {
        $('#editAddress'+addressId).modal('show');
    }



  return (
    <div className="row g-sm-4 g-3">
             {addressList && 
              addressList.map((list) => {
                return(
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                       
                          <div className="address-box">
                            <div>
                              <div className="label">
                                <label>{list.address_type}</label>
                              </div>
                              <div className="table-responsive address-table">
                                  <tbody>
                                    <tr>
                                       <td colSpan={2}><b>{list.customer_name}&nbsp;&nbsp;&nbsp;&nbsp;{list.mobileno}&nbsp;&nbsp;&nbsp;&nbsp;
                                       <a href="#"  onClick={e => editAddressClick(list._id)} >Edit</a>&nbsp;&nbsp;&nbsp;&nbsp;
                                       <a href="#"  onClick={e => deleteAddressConfirm(list._id)}>Delete</a></b></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>{list.address}, {list.locality},{list.landmark},{list.city},{list.state},{list.pincode},{list.mobileno}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                              </div>
                            </div>
                            {/*
                             <div className="button-group">
                              <button className="btn btn-sm add-button w-100" data-bs-toggle="modal" data-bs-target="#editProfile"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                Edit</button>
                              <button className="btn btn-sm add-button w-100" data-bs-toggle="modal" data-bs-target="#removeProfile"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /></svg>
                                Remove</button>
                            </div>
                            */}
                          </div>
                          <Addaddress addressObj={list} addressId={list._id} setState={setState} resetState={resetState}/>
                          <Alert alertId={"deleteConfirm"+list._id} cancelBtn={"CANCEL"} confirmBtn={"YES, DELETE"} msg={"Are you sure you want to delete this address ?"} func={e => deleteAddressClick(list._id)} alertSize={"md"} />
                        </div>
                      )
                    })
                  }

    </div>
  )
}

export default Address2
