import {useNavigate, useParams} from 'react-router-dom';
import React,{ useState, useEffect }  from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { getCategoryList } from '../utils/DatabaseAccess';
import { isDesktopView,urlEncode } from '../utils/Generic';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Category = () => {
  const [isLoading, setIsLoading] =useState(true);
  const [isDesktop, setIsDesktop] =useState(isDesktopView());
  const [lists, setLists]=useState([]);
  const navigate = useNavigate();
  const  {categoryId,categoryName} = useParams();
  useEffect(()=>{
    getHomeData();
  },[]);

  const getHomeData = async () => {
    const res = await getCategoryList({});
    if(res && res.result!="false"){
      localStorage.setItem("token", res.token);
        setLists(res.data);
    }
    setIsLoading(false)
  }

  const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      initialSlide: 0.15,
      adaptiveHeight: false,
  };


  return (
    <>
     <section className="category-section-3">
        <div className="container-fluid-lg">
            <Slider {...settings} >
            {lists.map((item) => {
               return(
                  <div className="category-box-list" >
                    <Link to={`/category/${item._id}/`+urlEncode(item.name)} className="category-name" >
                     <img src={process.env.REACT_APP_DB_URL+"/uploads/"+item.image} className="img-fluid blur-up lazyloaded" alt="" />          
                    {/*
                    {categoryId === item._id ?
                    isDesktop ?
                    <h3 className="fw-bold theme-color">{item.name}</h3>
                    :
                    <h4 className="fw-bold theme-color">{item.name}</h4>
                    :
                    <h4 >{item.name}</h4>
                    }
                    */}
                    </Link>
                  </div>
                )
            })}

            {!isDesktop &&
            <>
              <div ><div></div></div>
              <div ><div></div></div>
              <div ><div></div></div>
            </>
            }
            </Slider>
            { isLoading && lists && lists.length == 0 &&
            <>
            <div className="desktop-only">
            <Grid container spacing={2} className="desktop-only">
              <Grid item xs={2}>
                <Skeleton variant="circular" width={180} height={180}/>
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="circular" width={180} height={180}/>
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="circular" width={180} height={180}/>
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="circular" width={180} height={180}/>
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="circular" width={180} height={180}/>
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="circular" width={180} height={180}/>
              </Grid>
            </Grid>
            </div>
            <div className="mobile-only">
            <Grid container spacing={2} >
              <Grid item xs={3}>
                <Skeleton variant="circular" width={80} height={80} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={80} height={80} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={80} height={80} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={80} height={80} />
              </Grid>
            </Grid>
            </div>
            </>
            }
        </div>
      </section> 
    </>
  )
}

export default Category
