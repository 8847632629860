import React,{ useState, useEffect }  from 'react'
import { Link } from 'react-router-dom';
import "./error.css"

const ErrorPage = () => {

  return (
  <>
<div className="error-content">
    <div className="container">
        <div className="row">
            <div className="col-md-12 ">
                <div className="error-text">
                    <h1 className="error">404 Error</h1>
                    <div className="im-sheep">
                        <div className="top">
                            <div className="body"></div>
                            <div className="head">
                                <div className="im-eye one"></div>
                                <div className="im-eye two"></div>
                                <div className="im-ear one"></div>
                                <div className="im-ear two"></div>
                            </div>
                        </div>
                        <div className="im-legs">
                            <div className="im-leg"></div>
                            <div className="im-leg"></div>
                            <div className="im-leg"></div>
                            <div className="im-leg"></div>
                        </div>
                    </div>
                    <h4>Oops! This page Could Not Be Found!</h4>
                    <p>Sorry bit the page you are looking for does not exist, have been removed or name changed.</p>
                    <center><div class="col-sm-2"><Link to="/" className="btn btn-primary text-white">Go to homepage</Link></div></center>
                </div>
            </div>
        </div>
    </div>
</div>
  </>
  )

}
export default ErrorPage
