import { Link } from 'react-router-dom';
import React,{useState,useEffect} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {extractDataFromPincode, getUserDetails, addNewAddress, updateAddress, updateUser} from "../utils/DatabaseAccess.js";
import $ from 'jquery'; 
window.$ = window.jQuery = $; 

const Addaddress = (props) => {
    const [ output , setOutput ] = useState(); 
    const [userObj, setUserObj] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [addressType, setAddressType] = useState("home");
    const [addressObj, setAddressObj] = useState();
    const [errors, setErrors] = useState({});
    const [effectTrigger, setEffectTrigger] = useState(true);

    function sleep (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }
    useEffect(()=>{
        if (effectTrigger){ 
            const userid = localStorage.getItem("useriid");
              (async () => {
                const userData = await getUserDetails({userId:userid});
                setUserObj(userData);
                console.log("userData=",userData);
              })();
            if(props?.addressObj?.address_type) {
                setAddressType(props.addressObj.address_type);
                setAddressObj(props.addressObj);
            }
            setEffectTrigger(false);
        }
    }, [userObj,errors,props]);

    const resetState = async () => {
        sleep(1000).then(() => {
        setEffectTrigger(false);
      });
    }
    const setState = async () => {
      setEffectTrigger(true);
    }
    
    const setFieldsErrors = async (data) => {
		await setState();
        let err_data = errors;
        for (var key in data) {
            err_data[key] = data[key];
        }
        setErrors(err_data);
      await resetState();
    }
    const validatePincode= async (pincode) =>{
        let state = false;
        if (pincode.length == 6) {
            const data = await extractDataFromPincode(pincode);
            if (data.length == 0) { 
                setFieldsErrors({pincode : "Please enter valid pincode !"});
            } else if (data.city != "Bangalore") {
                setFieldsErrors({pincode : "Only Bangalore pincodes supported !"});
            } else {
                setFieldsErrors({pincode : ""});
                state = true;
            }
        };
        return state;
    };

    const handleSubmit=async (evt)=>{
        evt.preventDefault();
        setErrors({});
        let is_validation_passed = true;
        const userid= localStorage.getItem('useriid');
        const selectedAddressId=evt.target.addressId.value;
        var addressId = ""
        if (selectedAddressId != "new") {
            addressId = selectedAddressId;
        }
        const customer_name=evt.target.customer_name.value;
        if (!customer_name) {
            await setFieldsErrors({customer_name : "Please enter customer name !"});
            is_validation_passed = false;
        }
        const mobileno=evt.target.mobileno.value;
        if (!mobileno) {
            await setFieldsErrors({mobileno : "Please enter mobile name !"});
            is_validation_passed = false;
        }
        if (mobileno && (! mobileno.match(/^[0-9]+$/) || mobileno.length != 10)) {
            await setFieldsErrors({mobileno : "Please enter valid 10 digit mobile no !"});
            is_validation_passed = false;
        }
        const address=evt.target.address.value;
        if (!address) {
            await setFieldsErrors({address : "Please enter address !"});
            is_validation_passed = false;
        }
        const pincode=evt.target.pincode.value;
        if (!pincode) {
            await setFieldsErrors({pincode : "Please enter pincode !"});
            is_validation_passed = false;
        }
        if (!validatePincode(pincode)) {
            is_validation_passed = false;
        }
        if (pincode.length != 6 || !pincode.match(/^[0-9]+$/)) {
            await setFieldsErrors({pincode : "Please enter valid pincode !"});
            is_validation_passed = false;
        }
        const city=evt.target.city.value;
        if (!city) {
            await setFieldsErrors({pincode : "Please enter city !"});
            is_validation_passed = false;
        }
        const state=evt.target.state.value;
        if (!state) {
            await setFieldsErrors({state : "Please enter state !"});
            is_validation_passed = false;
        }
        const locality=evt.target.locality.value;
        if (!locality) {
            await setFieldsErrors({locality : "Please enter locality !"});
            is_validation_passed = false;
        }
        const alternate_mobileno=evt.target.alternate_mobileno.value;
        if (alternate_mobileno && (!alternate_mobileno.match(/^[0-9]+$/) || alternate_mobileno.length != 10)) {
            await setFieldsErrors({alternate_mobileno : "Please enter valid 10 digit mobile no !"});
            is_validation_passed = false;
        }
        const landmark=evt.target.landmark.value;
        let email = null;
        if (! userObj || !userObj.email) {
            email=evt.target.email.value;
            if (!email) {
                await setFieldsErrors({email : "Please enter email address !"});
                is_validation_passed = false;
            }
            if (!email.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)) {
                await setFieldsErrors({email : "Please enter valid email address !"});
                is_validation_passed = false;
            }
        }
        const address_type=evt.target.address_type.value;
        if (! is_validation_passed) {
            return false;
        }
              
        const userId = localStorage.getItem("useriid");
        if (email) {
            await updateUser({userId,email});
        }
        const data = {
            userId,
            customer_name,
            address,
            pincode,
            city,
            mobileno,
            locality,
            state,
            landmark,
            alternate_mobileno,
            address_type
        };
        if (addressId) {
            data['addressId'] = addressId;
            const obj = await updateAddress(data);
            setAddressObj(obj);

        } else {
            const obj = await addNewAddress(data);
            setAddressObj(obj);
        }
        $('#editAddress'+props.addressId).modal('hide');
        await props.updateAddress();
      };
      
  return (
    <>
       { props && 
       <div className="modal fade theme-modal" id={"editAddress"+props?.addressId} aria-labelledby="exampleModalLabel2" aria-modal="true" role="dialog" data-bs-keyboard="false" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-sm-down">
        
          <div className="modal-content">
          <form onSubmit={handleSubmit}  className="needs-validation" novalidate>
            <input type="hidden" name="addressId" defaultValue={props?.addressId} />
            <div className="modal-header">
           
              <h5 className="modal-title" id="exampleModalLabel2">Add a new address </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa fa-solid fa-xmark" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row g-4">
                <div className="col-6">
                    <div className="form-floating theme-form-floating">
                        <input type="text" className="form-control"  defaultValue={addressObj?.customer_name} data-constraints="  @Required"  id="form-login-customer_name"  name="customer_name" placeholder="Full Name" />
                        <label htmlFor="name">Full Name</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.customer_name}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating theme-form-floating">
                        <input type="text" className="form-control"  defaultValue={props.addressObj?.mobileno} data-constraints="  @Required"  id="form-login-mobileno"  name="mobileno" placeholder="10 digit Mobile No" />
                        <label htmlFor="mobileno">Mobile Number</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.mobileno}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating theme-form-floating">
                            <input type="text" className="form-control"  defaultValue={addressObj?.pincode} onChange={e => validatePincode(e.target.value)} data-constraints="@Required"  name="pincode" id="form-login-pincode" placeholder="Enter Pincode" maxLength="6" />
                            <label htmlFor="pincode">Pin Code</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.pincode}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating theme-form-floating">
                        <input type="text" className="form-control"  defaultValue={addressObj?.locality} data-constraints="  @Required"  id="form-login-locality"  name="locality" placeholder="Locality" />
                        <label htmlFor="Locality">Locality</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.locality}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-floating theme-form-floating">
                        <textarea className="form-control h-25" rows="4" defaultValue={addressObj?.address} data-constraints="  @Required"  id="form-login-address"  name="address" placeholder="Enter New Address" ></textarea>
                        <label htmlFor="address">Address(Area and Street)</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.address}
                    </div>
                </div>
                <div className="col-6">
                        <div className="form-floating theme-form-floating">
                          <input type="text" className="form-control" defaultValue={"Bangalore"} data-constraints="@Required" name="city"  id="form-login-city" placeholder="Enter City" readOnly />
                          <label htmlFor="city">Enter City</label>
                        </div>
                        <div className="text-danger text-bold" >{errors.city}
                        </div>
                </div>
                <div className="col-6">
                    <div className="form-floating theme-form-floating">
                    <select className="form-control" id="form-login-state" data-constraints="  @Required" name="state" readOnly>
                      <option selected>Karnataka</option>
                    </select>
                    <label htmlFor="state">State</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.state}
                    </div>
                </div>
                <div className="text-success text-bold" >
                  Available only in Bangalore(Karnataka) city. We are expanding soon in other cities !!!
                </div>
                <div className="col-6">
                    <div className="form-floating theme-form-floating">
                        <input type="text" className="form-control"  defaultValue={addressObj?.landmark}  data-constraints="  @Required"  id="form-login-landmark"  name="landmark" placeholder="Enter nearby landmark" />
                        <label htmlFor="landmark">Landmark(optional)</label>
                    </div>
                    <div className="text-danger text-bold" >{errors.landmark}
                    </div>
                </div>
                <div className="col-6">
                        <div className="form-floating theme-form-floating">
                          <input type="text" className="form-control" defaultValue={addressObj?.alternate_mobileno} name="alternate_mobileno"  id="form-login-alternate_mobileno" placeholder="Enter 10 Digit Mobile Number" />
                          <label htmlFor="alternate_mobileno">Alternale Mobile No.(Optional)</label>
                        </div>
                        <div className="text-danger text-bold" >{errors.alternate_mobileno}
                        </div>
                </div>
                { (userObj != null && !userObj.email) && 
                <div className="col-6">
                        <div className="form-floating theme-form-floating">
                          <input type="text" className="form-control" defaultValue={""} data-constraints="@Required" name="email"  id="form-login-alternate_mobileno" placeholder="Enter Email Address" />
                          <label htmlFor="email_address">Enter email address</label>
                        </div>
                        <div className="text-danger text-bold" >{errors.email}
                        </div>
                </div>
                }

                <div className="col-6">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"  defaultValue="home" name="address_type" defaultChecked />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Home (All day delivery)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultValue="work" name="address_type"  />
                      <label className="form-check-label" for="flexRadioDefault2">
                        Work (Delivery between 10AM - 5PM)
                      </label>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn theme-bg-color btn-md fw-bold text-light">Save changes</button>
                    <button type="button" className="btn btn-animation btn-md fw-bold" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default Addaddress
