import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const DeleteSteps = () => {
    return (
        <div className="container mt-5">
            <div className="row ">
                <div className="col-md-8">
                    <h1 className=" mb-4">How to Delete Your Account</h1>
                    <p>To delete your account, follow these steps:</p>
                    <ol className="list-unstyled">
                        <li className="mb-3">
                            <span className="fw-bold">1.</span> Open the app on your device.
                        </li>
                        <br />
                        <li className="mb-3">
                            <span className="fw-bold">2.</span> Navigate to <strong>Settings &gt; Account &gt; Delete Account</strong>.
                        </li>
                        <li className="mb-3">
                            <span className="fw-bold">3.</span> Follow the on-screen instructions to confirm account deletion.
                        </li>
                    </ol>
                    <p>
                        If you encounter any issues, please contact our support team at{' '}
                        <a href="mailto:krazeal.banglr@gmail.com" className="text-primary">
                            krazeal.banglr@gmail.com
                        </a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DeleteSteps;
