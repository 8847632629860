import { React, useState, useEffect } from "react";
import { useSearchParams, Link,useParams } from "react-router-dom";
import {getProductsPrice,getOrdersFromSummaryId,getSummaryInfoFromId,generateOrderInvoice,cancelOrder,returnOrder} from "../utils/DatabaseAccess.js"
import {humanDateTime,scrollToTop,urlEncode} from "../utils/Generic.js";
import {mapStateToProps, mapDispatchToProps} from '../redux/Redux';
import Alert from "../common/Alert.js"
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

const OrderTracking = (props) => {
  const [selectedAddress, setSelectedAddress] = useState({});
  const [relatedOrders, setRelatedOrders] = useState([]);
  const [summaryObj, setSummaryObj] = useState(null);
  const [productInfo, setProductInfo] = useState({});
  const [mainOrder, setMainOrder] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [queryParam] = useSearchParams();
  const {orderSummaryId, orderId} = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    OrderTrackingInit();
    scrollToTop();
  }, [orderId]);
  const generateInvoice = async () => {
      const data= {
          "order_id": summaryObj.shippingInfo?.order_id
      }
      const invoiceUrl = await generateOrderInvoice(data);
      const pdfViewerUrl = "/openpdf/"+encodeURIComponent(invoiceUrl);
      window.open(pdfViewerUrl, '_blank');
  };
  const orderReturnOrCancel = async (e) => {
      const userId = localStorage.getItem("useriid");
      var data = { 
          "orderSummaryId" : summaryObj._id,
          "userId" : userId
      }
      if (e.target.value == "cancel") {
          const res = await cancelOrder(data);
          alert(res.msg);
      } else if (e.target.value == "return") {
          data["orderId"] = mainOrder?._id;
          await returnOrder(data);
      } else if (e.target.value == "cancel_return") {
          data["orderId"] = mainOrder?._id;
          const res = await cancelOrder(data);
          alert(res.msg);
      }
      window.location.reload(false);
  };

  const getAllOrders =() => {
    let allOrders =[];
    for (var i in relatedOrders) {
      if (relatedOrders[i]._id !== mainOrder?._id){
        allOrders.push(relatedOrders[i]);
      }
    }
    return allOrders;
  };
  const OrderTrackingInit = async () => {
    const userId = localStorage.getItem("useriid");
    if (orderSummaryId) {
        const data = {userId,orderSummaryId};
        const summaryInfoRes = await getSummaryInfoFromId(data);
        if (!summaryInfoRes) {
          navigate("/pageNotFound");
          return;
        }

        const ordersList = await getOrdersFromSummaryId(data);
        setSummaryObj(summaryInfoRes);
        var total_discount = summaryInfoRes.actual_price - summaryInfoRes.total_price + summaryInfoRes.delivery_discount + summaryInfoRes.coupon_discount;
        setTotalDiscount(total_discount);
        var ordersListTemp = []
        for (var i=0;i<ordersList.length;i++) {
            var orderObj = ordersList[i];
            if (! orderId || orderId == orderObj._id) {
                setMainOrder(orderObj);
                setProductInfo(orderObj.productInfo);
            }
            ordersListTemp.push(orderObj);
        }
        setRelatedOrders(ordersListTemp);
    } else {
      navigate("/pageNotFound");
      return;
    }
    setIsLoaded(true);
    const resData = await getProductsPrice({userId});
    props.setCartCount(resData.total_qty);
  }

  const orderReturnCancel = () => {
    alert("Need to fix");
  }
  function OrderCancelButton () {
    if (! mainOrder) return;
    if (mainOrder?.status === "placed" || mainOrder?.status === "delivered") {
      const shippingInfo = summaryObj.shippingInfo;
      if (shippingInfo?.current_status == "DELIVERED") {
        return (
          <>
          <button type="button" className="btn btn-sm btn-animation" data-bs-toggle="modal" data-bs-target="#returnOrderModalId">
            Return Order
          </button>
          <div className="modal fade" id="returnOrderModalId" role="dialog" aria-modal="true" role="dialog"  >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="returnOrderModalLabel">Return Confirmation</h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-8">
                          <span className="text-center">
                              <img
                                style={{ height: "auto", width: "100px" }}
                                src={
                                  process.env.REACT_APP_DB_URL+"/uploads/" +
                                  mainOrder.productInfo.product_image
                                }
                                className="visible-xs blur-up lazyloaded checkout-image img-fluid"
                              /><br/>
                                &#8377;{mainOrder.productInfo.sale_price}
                          </span>
                    </div>
                    <div class="col-md-4 shadow-none p-3 bg-light rounded">
                      <p>product price: &#8377;{mainOrder.productInfo.price}</p>
                      <p>Krazeal discount: &#8377;{mainOrder.productInfo.price - mainOrder.productInfo.sale_price}</p>
                      <p>You saved &#8377;{mainOrder.productInfo.price - mainOrder.productInfo.sale_price} on this purchase</p>
                    </div>
                  </div>
                  <div class="shadow-sm p-3 bg-body rounded">
                  <b>If you return now, You may not be able to get avail this deal again. Do you still want to cancel</b>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                  </div>
                  <Link to={`/order-cancel-process?summary_id=${summaryObj?._id}&order_id=${mainOrder?._id}&type=return`} ><button type="button" data-bs-dismiss="modal" className="btn btn-sm btn-animation">Return Order</button></Link>
                  <button type="button" data-bs-dismiss="modal" className="btn theme-bg-color btn-sm fw-bold text-light">Don't Return</button>
                </div>
              </div>
            </div>
          </div>
          </>
        )
      } else {
        return (
          <>
          <button type="button" className="btn btn-sm btn-animation" data-bs-toggle="modal" data-bs-target="#cancelOrderModalId">
            Cancel Order
          </button>
          <div className="modal fade" id="cancelOrderModalId" role="dialog" aria-modal="true" role="dialog"  >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="cancelOrderModalLabel">Confirm Cancellation</h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-8">
                      {relatedOrders.map((list, index) => (
                          <span className="text-center">
                              <img
                                style={{ height: "auto", width: "100px" }}
                                src={
                                  process.env.REACT_APP_DB_URL+"/uploads/" +
                                  list.productInfo.product_image
                                }
                                className="visible-xs blur-up lazyloaded checkout-image img-fluid"
                              /><br/>
                                &#8377;{list.productInfo.sale_price}
                          </span>
                      ))}
                    </div>
                    <div class="col-md-4 shadow-none p-3 bg-light rounded">
                      <p>Total products price: &#8377;{summaryObj?.actual_price}</p>
                      <p>Krazeal discount: &#8377;{summaryObj?.actual_price -summaryObj?.total_price}</p>
                      {summaryObj?.coupon_discount >0 && <p>Coupon Discount :&#8377;{summaryObj?.coupon_discount}</p>}
                      {summaryObj?.delivery_discount >0 && <p>Delivery Discount : &#8377;{summaryObj?.delivery_discount}</p>}
                      <p>Total saving : &#8377;{totalDiscount}</p>
                    </div>
                  </div>
                  <div class="shadow-sm p-3 bg-body rounded">
                  <b>You saved &#8377;{totalDiscount} on this purchase. If you cancel now, You may not be able to get avail this deal again. Do you still want to cancel</b>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                  </div>
                  <Link to={`/order-cancel-process?summary_id=${summaryObj?._id}&order_id=${mainOrder?._id}&type=cancel`} ><button type="button" data-bs-dismiss="modal" className="btn btn-sm btn-animation">Cancel Orders</button></Link>
                  <button type="button" data-bs-dismiss="modal" className="btn theme-bg-color btn-sm fw-bold text-light">Don't Cancel</button>
                </div>
              </div>
            </div>
          </div>
          </>
        );
      }
    }
    /*
    else if (mainOrder?.status === "return") {
      const returnShippingInfo = mainOrder?.returnShippingInfo;
      if (! returnShippingInfo?.return_out_for_pickup_timestamp) {
        return (
          <>
          <button type="button" className="btn btn-sm btn-animation" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Cancel Return
          </button>
          <Alert alertId={"deleteConfirm"+mainOrder?._id} cancelBtn={"NO"} confirmBtn={"YES, CANCEL RETURN"} msg={"Are you sure you want to cancel return product ?"} func={e => orderReturnCancel(mainOrder)} alertSize={"md"} />
          </>
        );
      }
    }
    */
  }

  function OrderTrackingFlow () {
    if (mainOrder?.status === "placed" || mainOrder?.status === "delivered") {
      const shippingInfo = summaryObj.shippingInfo
      if (! shippingInfo) return;
      return (
        <div className="col-12 overflow-hidden">
          <ol className="progtrckr">
            <li className="progtrckr-done">
              <h5>Order Placed</h5>
              <h6>{humanDateTime(shippingInfo?.placed_timestamp)}</h6>
            </li>
            <li className={shippingInfo?.shipped_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Shipped</h5>
              <h6>{shippingInfo?.shipped_timestamp ? humanDateTime(shippingInfo?.shipped_timestamp) : "Pending" }</h6>
            </li>
            <li className={shippingInfo?.intransit_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>In Transit</h5>
              <h6>{shippingInfo?.intransit_timestamp ? humanDateTime(shippingInfo?.intransit_timestamp) : "Pending" }</h6>
            </li>
            <li className={shippingInfo?.outfordelivery_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Out For Delivery</h5>
              <h6>{shippingInfo?.outfordelivery_timestamp ? humanDateTime(shippingInfo?.outfordelivery_timestamp) :  "Pending" }</h6>
            </li>
            <li className={shippingInfo?.delivered_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Delivered</h5>
              <h6>{shippingInfo?.delivered_timestamp ? humanDateTime(shippingInfo?.delivered_timestamp) : "Pending" }</h6>
              <h5><b>{shippingInfo?.etd ? <>Expected delivery {humanDateTime(shippingInfo?.etd)}</> : ""}</b></h5>
            </li>
          </ol>
        </div>
      );
    } else if (mainOrder?.status === "return") {
      const returnShippingInfo = mainOrder?.returnShippingInfo
      const refundPaymentInfo = mainOrder?.refundPaymentInfo
      return (
        <div className="col-12 overflow-hidden">
          <ol className="progtrckr">
            <li className="progtrckr-done">
              <h5>Return Placed</h5>
              <h6>{returnShippingInfo?.return_scheduled_timestamp}</h6>
            </li>
            <li className={returnShippingInfo?.return_out_for_pickup_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Out for pickup</h5>
              <h6>{returnShippingInfo?.return_out_for_pickup_timestamp ? returnShippingInfo?.return_out_for_pickup_timestamp : "Pending" }</h6>
            </li>
            <li className={returnShippingInfo?.return_pickup_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Pickup Completed</h5>
              <h6>{returnShippingInfo?.return_pickup_timestamp ? returnShippingInfo?.return_pickup_timestamp : "Pending" }</h6>
            </li>
            <li className={returnShippingInfo?.return_pickup_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Refund Initiated</h5>
              <h6>{returnShippingInfo?.return_pickup_timestamp ? returnShippingInfo?.return_pickup_timestamp :  "Pending" }</h6>
            </li>
            <li className={refundPaymentInfo?.order_status === "Refunded" ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Refund Completed</h5>
              <h6>{refundPaymentInfo?.order_status === "Refunded" ? refundPaymentInfo?.current_timestamp : "Pending" }</h6>
              <h5><b>{returnShippingInfo?.etd ? "Expected delivery by " + returnShippingInfo?.pickup_scheduled_date : ""}</b></h5>
            </li>
          </ol>
        </div>
      );
    } else if (mainOrder?.status === "cancelled") {
      const cancelShippingInfo = summaryObj.cancelShippingInfo
      const shippingInfo = summaryObj.shippingInfo
      const refundPaymentInfo = summaryObj.refundPaymentInfo
      return (
        <div className="col-12 overflow-hidden">
          <ol className="progtrckr">
            <li className="progtrckr-done">
              <h5>Order Placed</h5>
              <h6>{humanDateTime(shippingInfo?.placed_timestamp)}</h6>
            </li>
            <li className={cancelShippingInfo?.current_status === "CANCELED" && cancelShippingInfo?.cancelled_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Order Cancelled</h5>
              <h6>{cancelShippingInfo?.cancelled_timestamp ? humanDateTime(cancelShippingInfo?.cancelled_timestamp) : "Pending" }</h6>
            </li>
            <li className={cancelShippingInfo?.current_status === "CANCELED" && cancelShippingInfo?.cancelled_timestamp ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Refund Initiated</h5>
              <h6>{cancelShippingInfo?.cancelled_timestamp ? humanDateTime(cancelShippingInfo?.cancelled_timestamp) : "Pending" }</h6>
            </li>
            <li className={refundPaymentInfo?.order_status === "Refunded" ? "progtrckr-done" : "progtrckr-todo" }>
              <h5>Refund Completed</h5>
              <h6>{refundPaymentInfo?.order_status === "Refunded" ? humanDateTime(refundPaymentInfo?.current_timestamp) : "Pending" }</h6>
            </li>
          </ol>
        </div>
      );
    } else if (mainOrder?.status === "failed") {
      const createTime = humanDateTime(summaryObj.createdAt);
      const updateTime = humanDateTime(summaryObj.updatedAt);
      return (
        <div className="col-12 overflow-hidden">
          <ol className="progtrckr">
            <li className="progtrckr-done">
              <h5>Order Placed</h5>
              <h6>{createTime}</h6>
            </li>
            <li className="progtrckr-done">
              <h5>Order Failed</h5>
              <h6>{updateTime}</h6>
            </li>
          </ol>
        </div>
      );
    }
  }
  
  return (
    <>
       <section className="order-detail">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-xxl-3 col-xl-4 col-lg-6">
              <div className="order-image">
            <Link to={`/product/${productInfo.productId}/${productInfo.product_name}`}>
                <img src={process.env.REACT_APP_DB_URL+`/uploads/${productInfo.product_image}`} className="img-fluid blur-up lazyloaded" alt="" />
            </Link>
                      <h6><b>{productInfo.product_name}</b></h6>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-8 col-lg-6">
              <div className="row g-sm-4 g-3">
                <div className="col-xl-4 col-sm-6">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-crosshair text-content"><circle cx={12} cy={12} r={10} /><line x1={22} y1={12} x2={18} y2={12} /><line x1={6} y1={12} x2={2} y2={12} /><line x1={12} y1={6} x2={12} y2={2} /><line x1={12} y1={22} x2={12} y2={18} /></svg>
                    </div>
                    <div >
                      <h5 className="text-content">Order Information</h5><br/>
                      <h6>Product Price  &#8377;{productInfo.sale_price}</h6>
                      <h6>
                      {parse(productInfo.product_color && productInfo.product_size ? "size:" + productInfo.product_size + "  color:" + productInfo.product_color + "<br/>" : "")}</h6>
                      <h6>Total Discount  &#8377;{(productInfo.price - productInfo.sale_price)*mainOrder?.qty}</h6>
                      <h6>Order items  {mainOrder?.qty}</h6><br/>
                      <h6><b>Total Price  &#8377;{productInfo.sale_price * mainOrder?.qty}</b></h6>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin text-content"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx={12} cy={10} r={3} /></svg>
                    </div>
                    <div >
                      <h5 className="text-content">Destination</h5>
                      <h6><br/><b>{summaryObj?.addressInfo.customer_name}</b><br/><br/>{summaryObj?.addressInfo.address}, {summaryObj?.addressInfo.city}, {summaryObj?.addressInfo.state}, {summaryObj?.addressInfo.pincode}<br/><br/><b>contact No {summaryObj?.addressInfo.mobileno}</b></h6>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-6">
                  <div className="order-details-contain">
                    <div className="order-tracking-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar text-content"><rect x={3} y={4} width={18} height={18} rx={2} ry={2} /><line x1={16} y1={2} x2={16} y2={6} /><line x1={8} y1={2} x2={8} y2={6} /><line x1={3} y1={10} x2={21} y2={10} /></svg>
                    </div>
                    <div >
                      <h5 className="text-content">More Action</h5>
                      <br/>
                      <button className="btn btn-sm btn-animation" onClick={generateInvoice} type="button" >Download Invoice</button><br/>
                      <OrderCancelButton />
                    </div>
                  </div>
                </div>
                <OrderTrackingFlow/> 
              </div>
            </div>
          </div>
        </div>
      </section>

      {relatedOrders.length > 1 &&
      <section className="order-table-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row p-3 bg-light mt-3 justify-content-md-center">
              <h4><b>Other items in this order</b></h4>
              {getAllOrders().map((list, index) => (
              <>
              <div className="row rounded border border-light mt-3 text-center bg-white p-3">
                <div className="col-md-2 product-image"> 
                <Link
                  to={`/order-tracking/${list.orderSummaryId}/${list._id}`}
                >
                  <img
                  style={{ height: "auto", width: "100px" }}
                    src={process.env.REACT_APP_DB_URL+`/uploads/${list.productInfo.product_image}`}
                    className="img-fluid blur-up lazyloaded visible-xs"
                    alt=""
                  />
                </Link>
                </div>
                <div className="col-md-3">
                  <ul>
                    <li className="name">
                      <Link to={`/order-tracking/${list.orderSummaryId}/${list._id}`}>
                        {list.productInfo.product_name}
                      </Link>
                      {/*
                      {list.productInfo.product_size &&
                      <h4 className="name">
                        size: {list.productInfo.product_size}
                      </h4>
                      }
                      {list.productInfo.product_color &&
                      <h4 className="name">
                        size: color: {list.productInfo.product_color}
                      </h4>
                      }
                      */}
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                    <ul>
                      <li className="name">
                      <h6 className="fw-bold">
                        {list.qty > 1 && `${list.qty} items at` }  {list.productInfo.sale_price * list.qty == 0 ? <div className="text-success">free</div>:<>&#8377;{list.productInfo.sale_price * list.qty}</>}
                      </h6>
                      </li>
                    </ul>
                </div>
                <div className="col-md-3">
                {list?.status === "NEW" || list?.status === "placed" || list?.status === "failed" ? humanDateTime(summaryObj.shippingInfo?.placed_timestamp,list?.status) : list?.status === "cancelled" ? humanDateTime(summaryObj.cancelShippingInfo?.cancelled_timestamp,list?.status) : list?.status === "delivered" && humanDateTime(summaryObj.shippingInfo?.delivered_timestamp,list?.status)}
                </div>
              </div>
              </>
              ))}
        </div>
        </div>
      </section>
      }
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTracking);
