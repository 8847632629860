import {useNavigate, useParams} from 'react-router-dom';
import React,{ useState, useEffect }  from 'react'
import { isDesktopView,urlDecode } from '../utils/Generic';
import { Document, Page, pdfjs } from "react-pdf";
//import "./pdf.css";

const PdfViewer = () => {
  const [isLoading, setIsLoading] =useState(true);
  const [isDesktop, setIsDesktop] =useState(isDesktopView());
  const [lists, setLists]=useState([]);
  const navigate = useNavigate();
  const  {pdf} = useParams();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
  <>
    <center>
    <a href={decodeURIComponent(pdf)} download>Download Invoice</a>
    <div width={"100%"}>
    <Document 
      file={decodeURIComponent(pdf)}
    >
      <Page pageNumber={1} />
    </Document>
    </div>
    </center>
  </>
  );
}
export default PdfViewer
