import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { isDesktopView } from '../utils/Generic';
import axios from 'axios';

function OlwCarousel() {
    const [index, setIndex] = useState(0);
    const [data , setData]= useState([]);
    const [isLoading, setIsLoading] =useState(true);
    const [isDesktop, setDesktop] =useState(isDesktopView());

    useEffect(() => {
      const intervalId = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % data.length);
      }, 8000);
      return () => clearInterval(intervalId);
    });
    useEffect(()=>{
        getHomeData();
    },[]);

    const getHomeData = async () => {
        const options ={
            headers: {
                "content-type" : "application/json; charset=utf-8",
                "Access-Control-Allow-Origin" : '*'
            }
        }
            await axios.get(process.env.REACT_APP_DB_URL+"/api/banner_list",{},options).then(res => {
            setData(res.data.data);
            setIsLoading(false);
        }).catch(err =>{
        },[])
    }

    return (
    <div className='container padding-slider' >
      {!isLoading && data?.length>0 && data[index] ?
      <img class="product-image swiper-slide swiper-slide-visible swiper-slide-active"
      src={process.env.REACT_APP_DB_URL+"/uploads/"+data[index]?.image} alt='sliderimage' 
      /> 
      :
      isDesktop ?
      <Skeleton width="100%" height="600px" style={{marginTop:"-120px"}} >
        <Typography>.</Typography>
      </Skeleton>
      :
      <Skeleton width="100%" height="300px" style={{marginTop:"-30px"}}>
        <Typography>.</Typography>
      </Skeleton>
      }
    </div>
   );
}
export default OlwCarousel
