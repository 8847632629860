import React from 'react'
import Main from './Main'
import OlwCarousel from './OlwCarousel'
import Category from './Category'
import HoverBox from './HoverBox'

const Home = () => {
  return (
    <>
    <Category/>
    <OlwCarousel/>
    <Main/> 
    <HoverBox/>
    </>
  )
}

export default Home
