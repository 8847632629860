import React,{ useState, useEffect }  from 'react'
const StarRating = (props) => {
  const [rating, setRating] = useState([false,false,false,false,false]);
  useEffect(()=>{
    getHomeData();
  },[]);
  const getHomeData = async () => {
    var overall_rating = 0;
    var rating_cnt = 0;
    if(props?.flipkart && parseFloat(props?.flipkart) !=0) {
        overall_rating += parseFloat(props?.flipkart);
        rating_cnt++;
    }
    if(props?.amazon && parseFloat(props?.amazon) !=0) {
        overall_rating += parseFloat(props?.amazon);
        rating_cnt++;
    }
    if(props?.rating && parseFloat(props?.rating) !=0) {
        overall_rating += parseFloat(props?.rating);
        rating_cnt++;
    }
    if (overall_rating !=0 && rating_cnt !=0) {
        overall_rating = overall_rating/rating_cnt;
    }

  var rating_new = [];
    if (overall_rating !=0) {
      for(var i=0;i<5;i++){
        if(i < overall_rating){
            rating_new.push(true);
        } else {
            rating_new.push(false);
        }
      }
      setRating(rating_new);
    }
    console.log("rating=",props,":overall rating=",overall_rating);
  }
    return (
            <ul className="rating">
            <li>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className={rating[0] ? "feather feather-star fill" : "feather feather-star"} >
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </li>
            <li>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className={rating[1] ? "feather feather-star fill" : "feather feather-star"}>
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </li>
            <li>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className={rating[2] ? "feather feather-star fill" : "feather feather-star"}>
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </li>
            <li>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className={rating[3] ? "feather feather-star fill" : "feather feather-star"}>
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </li>
            <li>
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className={rating[4] ? "feather feather-star false" : "feather feather-star"}>
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
              </svg>
            </li>
           </ul>
  );
};
export default StarRating;
