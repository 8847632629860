import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import StarIcon from '@mui/icons-material/Star';
import Parser from 'html-react-parser';
import ReactTimeAgo from 'react-time-ago'

const importJs = (urlOfTheLibrary) => {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
};

const logOut = (props) => {
    localStorage.removeItem('useriid');
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("password");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.setItem("cartcnt", 0);
    props.onResetClick()
    window.location.href="/login";
};

const getDaysDiff = (dateStr) => {
  let dbDate;
  let daysDiff = 0;
  if (!dateStr)
    return 0 
  try {
    dbDate = new Date(dateStr);
  } catch {
    return 0;
  }
  let today = new Date();
  try {
    daysDiff = Math.abs(dbDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
  } catch (err) {
    return 0;
  }
  return daysDiff;
}

const scrollToTop = () => {
  window.scrollTo(0, -50)
}

const humanDateTime = (dateStr,msg="") => {
  let dbDate;
  if (!dateStr)
    return dateStr; 
  try {
    dbDate = new Date(dateStr);
  } catch {
    return dateStr;
  }
  let dateDiff = getDaysDiff(dbDate);
  let today = new Date();
  let on  = "";
  if (msg !== ""){
    on = "on";
    msg = msg.capitalize();
  }

  if(dateDiff > 2) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return (<> {msg} {on} {dbDate.toLocaleDateString('en-US', options)} </>);
  } else if (today > dbDate) {
      return (<>{msg} <ReactTimeAgo date={dbDate} locale="en-US"/></>)
  }else{         
      return (<>{msg} <ReactTimeAgo future date={dbDate} locale="en-US" /></>)
  }

};

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});


const Star = (value="0") => {
  return (
    <>
    <Stack spacing={1}>
      <Rating
      name="half-rating-read"
      defaultValue={value}
      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      fontSize="inherit"
      precision={0.5}
      readOnly 
      size="small"
      />
    </Stack>
    </>
  );
}

const urlEncode = (url) => {
  var name = encodeURI(url);
  //return name.replaceAll(" ", "%20");
  return name;
}
const urlDecode = (url) => {
  var name = decodeURI(url);
  //return name.replaceAll("%20"," ");
  return name;
}

const sleep = (seconds) => {
  const start = Date.now();
  let now = start;
  let ms = seconds*1000;
  while (now - start < ms) {
    now = Date.now();
  }
}

const DbToHtmlFormatting = (additional_info) => {
  if (!additional_info) {
     return "";
  }
  var lines = "";
  var data = additional_info.split(/\r?\n/)
  var table_found = false;
  var bullet_found = false;
  for(var i=0;i<data.length;i++){
    var line = data[i];
    if(line.search(/^\s*$/) !== -1){
      if (table_found){
        lines+="</table>";
        table_found=false;
      }
      if (bullet_found){
        lines+="</table>";
        bullet_found=false;
      }
      lines+="<br/>";
      continue;
    }
    var temp =line;
    if(line.search(/^\s*\*/) !== -1){
      if (table_found){
        lines+="</table>";
        table_found=false;
      }
      if(!bullet_found){
        lines += "<table className=\"table table-borderless\">";
      }
      lines += "<tr><td className=\"text-danger\">&#10004;</td><td>"+temp.replace(/^\s*\*/,"")+"</td></tr>";
      bullet_found = true;
      console.log("HERE1");
    }else if(line.search(/^\s*\|/) !== -1){
      if (bullet_found){
        lines+="</table>";
        bullet_found=false;
      }
      if(!table_found){
        lines += "<table className=\"table info-table\">";
      }
      temp = temp.replace(/^\s*\|/,"<tr><td className=\"fw-bold\">");
      temp = temp.replace(/\|\s*$/,"</td></tr>");
      temp = temp.replace(/\|/g,"</td><td>");
      lines+=temp;
      table_found = true;
      console.log("HERE2");
    } else if (table_found){
      lines+="</table>";
      table_found=false;
      console.log("HERE3");
    } else if (bullet_found){
      lines+="</table>";
      bullet_found=false;
      console.log("HERE4");
    }

    if(line.search(/^\s*#/) !== -1){
      lines+="<h3 className=\"theme-color\"><b>"+line.replace(/#/g,"")+"</b>:</h3>";
    } else if(!table_found && !bullet_found) {
      lines+="<p>"+line+"</p>";
    }
  };
  return Parser(lines);
};

const isDesktopView = () => {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return false;
    } else {
      return true;
    }
}

export {
    importJs,
    logOut,
    humanDateTime,
    getDaysDiff,
    scrollToTop,
    Star,
    urlEncode,
    urlDecode,
    sleep,
    DbToHtmlFormatting,
    isDesktopView
};
