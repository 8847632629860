// utils/api.js
import axios from 'axios';

// Set up an Axios instance with the base URL from environment variables
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to attach token
api.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor to handle expired token
api.interceptors.response.use(
  response => response,
  error => {
    console.log(error,'hdshbcjdsbj')
    if (error.response && error.response.status === 403 || error.response.status === 401) {
      const errorMessage = error.response.data.message;
      if (errorMessage === "Session expired. Please log in again." || "Access denied. No token provided.") {
        console.log('sdhcjgsdchjsdc');
        // Clear both authToken and userId from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('useriid');

        // Redirect to login page
        window.location.href = '/login'; // Redirect to login page
      }
    }
    return Promise.reject(error);
  }
);

export default api;
