import Address from "../product/Address";
import Addaddress from "../product/Addaddress";
import { Link } from 'react-router-dom';
import Orderlist from "../order-tracking/Orderlist";
import {getAddressList, getPaymentUrl, createDbOrder, getCartList} from "../utils/DatabaseAccess.js";
import React from "react";
import { useState, useEffect } from "react";
import parse from 'html-react-parser';
import axios from "axios";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import { useForm } from "react-hook-form";
import {getUserDetails} from "../utils/DatabaseAccess.js";

const CheckOut = () => {
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressList, setAddressList] = useState(null);
  const [ccavanueData, setCcavanueData] = useState("");
  const [frameHeight , setFrameHeight] = useState("600")
  const [paymentDisplay , setPaymentDisplay] = useState("none")
  const [addressDisplay , setAddressDisplay] = useState("block")
  const [addAddressDisplay , setAddAddressDisplay] = useState("none")
  const [userObj, setUserObj] = useState("");
  const [errors, setErrors] = useState("");
  const [cartObjs, setCartObjs] = useState();
  const [priceDisplay, setPriceDisplay] = useState("block");
  const ref = React.useRef();
  useEffect(() => {
    getHomeData();
  }, []);

  const updateAddress = async () => {
    const userid = localStorage.getItem("useriid");
    const resData = await getAddressList({userId: userid});
    setAddressList(resData);
  }

  const getHomeData = async () => {
    const userid = localStorage.getItem("useriid");
    const cartInput = {
      userId: userid,
      nonDisplayProduct : false
    };
    const resCartData = await getCartList(cartInput);
    setCartObjs(resCartData);
    if (resCartData && resCartData.list.length > 0) {
      if(userid) {
          const obj = await getUserDetails({userId : userid});
          setUserObj(obj);
      }
      const resData = await getAddressList({userId: userid});
      setAddressList(resData);
      if(!selectedAddress && resData.length > 0 ) {
          setSelectedAddress(resData[-1]);
      }
    }
  };

  const changeDisplay = async (e) => {
    setPaymentDisplay("none");
    setAddressDisplay("block");
    setPriceDisplay("block");
  }
  const handleAddAddress = async (e) => {
    setAddressDisplay("none");
    setAddAddressDisplay("block");
  }
  const handleAddressChange = (obj) => {
    this.checked = true;
  }
  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    setPaymentDisplay("block");
    setAddressDisplay("none");
    const userid = localStorage.getItem("useriid");
    const selectedAddressId = e.currentTarget.elements.addressId.value;

    if (addressList) {
        await addressList?.map((list) => {
            if(list._id == selectedAddressId) {
                setSelectedAddress(list);
            };
        });
    }
    const orderSummaryRes =await  createOrder(selectedAddressId);
    const orderSummaryId= orderSummaryRes._id;
    var formData = {
        "addressId": selectedAddressId,
        "orderSummaryId":orderSummaryId,
        "userId":userid
    }
    const paymentUrl = await getPaymentUrl(formData);
    await setCcavanueData(paymentUrl);

    if (navigator.userAgent.match(/Android/i) 
        || navigator.userAgent.match(/webOS/i) 
        || navigator.userAgent.match(/iPhone/i) 
        || navigator.userAgent.match(/iPad/i) 
        || navigator.userAgent.match(/iPod/i) 
        || navigator.userAgent.match(/BlackBerry/i) 
        || navigator.userAgent.match(/Windows Phone/i) 
    ) {
		setFrameHeight("1200px");
    setPriceDisplay("none");
    } else { 
		setFrameHeight("700px");
    }
    //console.log("FRAME HEIGHT",ref.current.contentWindow.document.body.scrollHeight);
    {/*
    $(document).ready(function(){$("iframe#paymentFrame").on('load',function() {
        window.addEventListener("message", function(e) {setFrameHeight(e.data["newHeight"]+"px"); }, false);
      });
    });
    */}
  };

  const createOrder = async (selectedAddressId) => {
    const userid = localStorage.getItem("useriid");
    //const total_price=localStorage.getItem("total_price")
    const cartInput = {
      userId: userid
    };
    let total_price = 0;
    var cartData = [];
    cartObjs?.list.map((list) => {
        var c_data = {productId :   list.productId._id,
                      qty       :   list.qty};
        if ("size" in list) {
          c_data['product_size'] = list.size;
        }
        if ("color" in list) {
          c_data['product_color'] = list.color;
        }
        cartData.push(c_data);
        total_price += Number(list.total_price);
    });
    const data = {
      "userId"              : userid,
      "addressId"           : selectedAddressId ,
      "productInfo"         : cartData
    };
    const response = await createDbOrder(data);
    return response;
  };

  defineElement(lottie.loadAnimation);
  return (
    <>
    {cartObjs?.list.length === 0 ?
      <section className="cart-section section-b-space">
        <div className="container-fluid-lg">
                <div className="summery-contain text-center">
                  <ul>
                    <li><h1><svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 00 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
</svg></h1></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><h2 className="price p-1 theme-color">Your cart is empty!</h2></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><h4 className="price p-1">Explore our crazy deals</h4></li>
                  </ul>
                  <ul><li></li></ul>
                  <ul>
                    <li><Link to="/" >Continue shopping</Link></li>
                  </ul>
                </div>

        </div>
      </section>
      : cartObjs?.list.length > 0 &&
      <section className="checkout-section-2 section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <div className="col-lg-8">
              <div className="left-sidebar-checkout">
                <div className="checkout-detail-box">
                  <ul>
                    <li>
                      <div className="checkout-icon">
                        <lord-icon
                          target=".nav-item"
                          src="https://cdn.lordicon.com/ggihhudh.json"
                          trigger="loop-on-hover"
                          colors="primary:#121331,secondary:#646e78,tertiary:#0baf9a"
                          className="lord-icon"
                        ></lord-icon>
                      </div>
                      <div className="checkout-box" style={{ display: paymentDisplay }}>
                        <div className="row">
                          <div className="col-sm-9">
                            <div className="checkout-title">
                              <h4>Delivery Address</h4>
                            </div>
                            <div className="checkout-detail" >
                              <b>{selectedAddress?.customer_name}</b> {selectedAddress?.address}, {selectedAddress?.locality}, {selectedAddress?.landmark}, {selectedAddress?.city}, {selectedAddress?.state} - <b>{selectedAddress?.pincode}</b>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button onClick={changeDisplay} className="btn btn-sm border border-danger">Change Address</button>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-box" style={{ display: addressDisplay }}>
                        <div className="checkout-title">
                          <h4>Delivery Address</h4>
                        </div>
                        <div className="checkout-detail" >
                          <form onSubmit={handleAddressSubmit} >
                          <div className="row g-4" >
                            {addressList && addressList?.reverse().map((list) => {
                              return (
                                <div className="col-xxl-12 col-lg-12 col-md-6">
                                  <div className="delivery-address-box">
                                    <div>
                                      <li>
                                        <input
                                          className="form-check-input" 
                                          type="radio"
                                          name="addressId"
                                          value={list._id} 
                                          defaultChecked
                                        ></input>
                                      </li>
                                      <div className="label text-black bg-warning">
                                        <label>{list.address_type}</label>
                                      </div>
                                      <ul className="delivery-address-detail">
                                        {/* <li>
                                      {list._id}
                                    </li> */}
                                        <li>
                                          <h6 className="text-content">
                                            <span className="text-title">
                                              <b>{list.customer_name}</b>
                                            </span>
                                            <span className="text-title">
                                              {list.mobileno}
                                            </span>
                                          </h6>
                                        </li>
                                        <li>
                                          <p className="text-content">
                                            <span className="text-title">
                                                {list.address}, {list.locality}, {list.landmark}, <b>{list.city}</b>, {list.state} {list.pincode}
                                            </span>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="row g-4">
                            <div className="col-md-4">
                              {addressList?.length > 0 &&
                              <button type="submit" name="confirm-address" className="btn bg-danger text-white btn-sm mt-4">Confirm Delivery Address</button> }
                            </div>
                          
                            <div className="col-md-4 no-p">

                              <button type="button"  className="btn theme-bg-color text-white btn-sm mt-4" data-bs-toggle="modal" data-bs-target="#editAddressnew">Add New Address</button>
                            </div>
                            <div className="text-danger text-bold" >{errors.address}</div>
                          </div>
                          </form>
                          <Addaddress addressId={"new"} userObj={userObj} updateAddress={updateAddress} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="checkout-icon">
                        <lord-icon
                          target=".nav-item"
                          src="https://cdn.lordicon.com/qmcsqnle.json"
                          trigger="loop-on-hover"
                          colors="primary:#0baf9a,secondary:#0baf9a"
                          className="lord-icon"
                        ></lord-icon>
                      </div>
                      <div className="checkout-box">
                        <div className="checkout-title">
                          <h4>Payment Option</h4>
                        </div>
                        <div className="checkout-detail" style={{ display: paymentDisplay }}>
                          <div className="accordion accordion-flush custom-accordion" id="accordionFlushExample">
                            {/*
                            <div className="accordion-item">
                              <div
                                className="accordion-header"
                                id="flush-headingFour"
                              >
                                <div
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                >
                                  <div className="custom-form-check form-check mb-0">
                                    <label
                                      className="form-check-label"
                                      htmlFor="cash"
                                    >
                                      <input
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="cash"
                                        defaultChecked
                                      />{" "}
                                      Cash On Delivery
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            */}
                            <div className="accordion-item">
                                {/*
                                <div
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                >
                                  <div className="custom-form-check form-check mb-0">
                                    <label
                                      className="form-check-label"
                                      htmlFor="credit"
                                    >
                                      <input
                                        className="form-check-input mt-0"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="credit"
                                      />
                                      Online payment
                                    </label>
                                  </div>
                                */}
                              <iframe ref={ref} height={frameHeight}  width="100%" scrolling="No" frameborder="0"  id="paymentFrame" src={decodeURIComponent(ccavanueData)} ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Orderlist priceDisplay={priceDisplay} />
          </div>
        </div>
      </section>
    }
    </>
    );
};

export default CheckOut;
