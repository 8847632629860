import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Rectangular(props) {
    return (
      <>
      <div className="row">
      <div className="col-8">
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={900} height={400} />
      </Stack>
      </div>
      <div className="col-4">
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={400} height={250} />
      </Stack>
      </div>
      </div>
      </>
    );
}
