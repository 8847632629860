import React,{ useState, useEffect }  from 'react'
import {scrollToTop,DbToHtmlFormatting} from "../utils/Generic";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState("");
  useEffect(() => {
    scrollToTop();
    PrivacyPolicyInit();
  }, []);

  const PrivacyPolicyInit = async () => {
    var raw_text = `
<center><h2>Terms of Use</h2></center>
<center><h4>Dealsmith India Private Limited (www.krazeal.com)</h4></center>

This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name  “www.krazeal.com” (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”)
The Platform is owned by Dealsmith India Private Limited, a company incorporated under the Companies Act, 1956 with its registered office at Edifice Building First Floor No P3 & P4 Sector 12 10th Main Road Jeevan Bhima Nagar Main Road Bangalore 560075, Karnataka, India (hereinafter referred to as "Krazeal").
Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with Dealsmith India Private Limited with brand name “Krazeal” and website :   “www.krazeal.com””” these terms and conditions including the policies constitute Your binding obligations, with “Krazeal”.
For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing Registration Data while registering on the Platform as Registered User using the computer systems. “Krazeal” allows the User to surf the Platform www.krazeal.com or making purchases without registering on the Platform. The term "We", "Us", "Our" shall mean Dealsmith India Private Limited.
ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by “Krazeal” (including but not limited to Privacy Policy available at Privacy) as amended from time to time.

#Account and Registration

It is the user's responsibility to keep the details of the account and password confidential and you agree to accept responsibility for all activities that occur under Your Account or Password. Krazeal Online “www.krazeal.com”shall not be liable to any person for any loss or damage which may arise as a result of any failure by the user to protect the account details or the password. In case of any suspected breach please contact us immediately. If Krazeal Online “www.krazeal.com”has reason to believe that there is likely to be a breach of security or misuse of the Krazeal Online “www.krazeal.com”Site, we may require you to change your password or we may suspend your account without any liability to Krazeal Online “www.krazeal.com”.com.
You also agree to:

*Provide true, accurate, current and complete information about yourself as prompted by Krazeal Online “www.krazeal.com” website during the registration process (such information being the "Registration Data")
*Maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, incomplete, or not current or if Krazeal Online “www.krazeal.com”has reasonable grounds to suspect that such information is untrue, inaccurate, not current or not in accordance with the User Agreement, Krazeal Online “www.krazeal.com”will have the right to indefinitely suspend or terminate your membership and refuse to provide you with access to the Site.
*Customers registered through international phone numbers can not avail Club benefits at the Krazeal Online “www.krazeal.com”.
*Customers registered through international phone numbers can get only an Online discount i.e at the website or app
*Any changes in the offer and discount policy will be solely the company’s decision & the company has the right to modify/remove/put it on hold anytime as it deems fit.

#Communications

When You use the Platform or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.
We reserve the right to make changes to our website, policies, and these Conditions of Use at any time. You will be subject to the policies and Conditions of Use in force at the time that you use the website or that you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.

#TERMS AND CONDITIONS

*These Terms sets forth the terms and conditions that apply to the sale and purchase of the products between “Krazeal” and you. Change in law or changes in “Krazeal Online “www.krazeal.com”Online business may require changes to be made to the Terms from time to time (“Revised Terms”). As such, Krazeal Online “www.krazeal.com” encourages you to review the Terms and any other terms and condition periodically for any such changes. It is your responsibility to carefully read, agree with and accept the terms or any other terms and condition.
*You agree that “Krazeal” makes no warranty as to the accuracy, comprehensiveness, or correctness of any Product(s) on the Website   “Krazeal Online “www.krazeal.com” (“Website”) and provides all Product(s) on an "as is" basis. All the products sold at  Krazeal Online “www.krazeal.com””” belong to different companies with different brand names. The product description, specifications, sizes, patterns, colours, services and warranty are all mentioned on product description page and solely designed by manufactures and marketers of same brand.  Krazeal Online “www.krazeal.com” is a online platform offering deals on different product categories of different brand names sold by manufacturer or promoters. All the service features like guarantee/warranty will be provided by manufacturer/promoter of that brand however if any of products is found defective/not in working condition the same will be replaced or purchase value of same will be refunded as per company return/replacement policy.
*The products/services and information displayed on the Website is an "invitation to offer" to public at large. Your order for purchase constitutes your "offer" which shall be subject to acceptance or rejection by “Krazeal” and shall further subject to the terms and conditions as listed below. All accepted offers shall be fulfilled by Krazeal Online “www.krazeal.com”. If you have supplied your valid email address or contact number, “Krazeal” will notify you by email or through SMS (as the case may be) as soon as possible to confirm receipt of your order and email you again to confirm details and therefore process the order. Acceptance of your order will take place upon dispatch of the Product(s) ordered. No act or omission of “Krazeal” prior to the actual dispatch of the Product(s) ordered will constitute acceptance of your offer.
*Krazeal Online “www.krazeal.com”.  does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of any particular User that You choose to deal with on the Platform and use Your best judgment in that behalf
*SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THE PLATFORM.
*Persons who are "incompetent to contract" under the Indian Contract Act, 1872 including minors, un-discharged insolvents etc or otherwise barred by law are not eligible to place the order for the product or use or transact on the Website. Persons under the age of 18 years cannot transact through or use the Website. Website is freely accessible to the all the end user however subject to the terms of use and privacy policy or any other terms and conditions.

#SERVICES; ORDER, PRICING AND PAYMENT

#1.	The Services shall include

*Supply of the ordered product to you;
*Procuring a logistics company experienced with collection and transportation to deliver your ordered Products (“Logistics Supplier”); and
*"Krazeal" customer support, enabling you to contact Krazeal Online “www.krazeal.com” at ………… with any queries that you may have in relation to your purchased Product/s.

#2
You agree to pay the full amount payable for the Product as indicated during the order process, including any shipping costs or charges incurred with that order. All prices are displayed and are payable in Indian National Rupees.

#3
Krazeal Online “www.krazeal.com” may not accept your order for Products if (a) the item/s is/are out of stock; (b)  Krazeal Online “www.krazeal.com” is unable to obtain authorisation for your payment; (c) shipping restrictions apply to a particular Product / Location; (d) “Krazeal” finds a Product or pricing error; or (e) for any other reason at Krazeal Online “www.krazeal.com” sole discretion. In any of these situations, Krazeal Online “www.krazeal.com” will inform you that your order has not been accepted, will not process your payment and will not be liable to you or any third party by reason of our decision to decline processing an order, or unwinding or suspending any transaction after processing has begun.

#4
From time to time there may be errors in the pricing that appears on the Website or during the order process. There may also be errors in transmission resulting in the interruption of your purchase. Without admissions Krazeal Online “www.krazeal.com” will in good faith try to resolve any issues that may arise as a result of such errors. You agree to cooperate with  Krazeal Online www.krazeal.com to resolve such issues, and you agree that  Krazeal Online “www.krazeal.com” shall have full discretion to determine the appropriate resolution in such cases.

#5
While availing any of the payment method(s) available on the Website, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to You due to
*Lack of authorization for any transaction/s, or
*Exceeding the pre-set limit mutually agreed by You and between "Bank/s", or
*Any payment issues arising out of the transaction, or
*Decline of transaction for any other reason/s

#6
Krazeal Online “www.krazeal.com” may offer multiple payment options through various instrument(s) or mode(s) for the convenience of the customers. Krazeal Online “www.krazeal.com” reserves the right to add or remove or modify the terms and conditions for such payment options without prior notice. Without prejudice to the aforesaid, usage of any of the above payment options shall at all times be subject to rules, regulations and guidelines under applicable law and those prescribed by RBI, issuer banks, acquiring banks, facility providers and other intermediaries involved in the payment process.

#7
All payment transactions are subject to validation by the issuer as applicable. If the issuer of your payment instrument(s) refuses to authorise payment Krazeal Online “www.krazeal.com”has the right to cancel the order. Krazeal Online “www.krazeal.com” cannot be held responsible if this should result in a delay in dispatching your goods/Products.

#8
Krazeal Online “www.krazeal.com” reserves the right to refuse to process transactions by buyers with a prior history of questionable charges including without limitation breach of any agreements by buyer with  Krazeal Online “www.krazeal.com” or breach/violation of any law or any charges imposed by issuing bank or breach of any policy.

#9
Krazeal Online “www.krazeal.com” may do such checks as it deems fit before approving the receipt of/buyers’ commitment to pay (for Cash On Delivery transactions) transaction price from the Buyer for security or other reasons at the discretion of Krazeal Online “www.krazeal.com”. As a result of such check if  Krazeal Online “www.krazeal.com” is not satisfied with the creditability of the buyer or genuineness of the transaction/transaction price, it will have the right to reject the receipt of/buyers’ commitment to pay transaction price.

#10
All billing and registration information provided must be truthful and accurate. Providing any untruthful or inaccurate information constitutes a breach of these Terms. By confirming your purchase at the end of the process, you agree to accept and pay for the item/s requested.

#11
Certain orders may constitute improper use of the Website and Services.  Krazeal Online “www.krazeal.com”reserves the right, at its sole discretion, to refuse or cancel any order for any reason. Your Account may also be restricted or terminated for any reason, at Krazeal Online “www.krazeal.com” sole discretion.

#12
In the event that a Product is listed at an incorrect price due to an error in pricing Krazeal Online “www.krazeal.com” shall have the right, at Krazeal Online “www.krazeal.com” discretion, to refuse or cancel any orders placed for that Product. In the event that a Product is mispriced Krazeal Online “www.krazeal.com” may, at Krazeal Online “www.krazeal.com”s discretion, either contact you for instructions or cancel your order and notify you of such cancellation.

#13
Krazeal Online “www.krazeal.com” reserves the right, at its sole discretion and without prior notice, to limit the quantity / value of items purchased per person per household or per order. These restrictions may be applicable to order placed by same account, the same credit card and also to orders that use the same billing and/or shipping address.

#14
You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.

#15
Krazeal Online “www.krazeal.com” reserves the right to refuse to process transactions by buyers with a prior history of questionable charges including without limitation breach of any agreements by buyer with Krazeal Online “www.krazeal.com”  or fraudulent activities ( without limitation for example  wrong products being returned,  etc) or breach/violation of any law or any charges imposed by issuing bank or breach of any policy.

#16
Risk of loss and title for the Products shall pass to you upon delivery of your purchased Products by the Logistics Supplier.

#17
Krazeal Online “www.krazeal.com” is not responsible for any delays in the shipping of your ordered and purchased Product/s, lost or missing Product/s or the incorrect delivery of the Product/s to you, which is due to your fault or due to inaccurate, false or incomplete information provided by you.

#18
Should you receive an item that is not in perfect condition please contact us immediately at enquiry@krazeal.com and arrange for the faulty Product to be returned to us.

#19.	RETURNS

Please note card refunds may take up to 15 business days for your bank to complete, depending on their processing time. This can vary greatly between card issuers, and Krazeal Online “www.krazeal.com”shall not be liable for any delays in refund by card companies or banks. Krazeal Online “www.krazeal.com” offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Customers card/bank accounts. This is on account of the multiplicity of organisations involved in processing of online transactions, the problems with Internet infrastructure currently available and working days/holidays of financial institutions.

#20.	LIMITATION OF LIABILITY AND INDEMNITY

*You agree, to the fullest extent permitted by law, to indemnify and hold  Krazeal Online “www.krazeal.com”, its directors, officers, employees, affiliates, group companies, agents, contractors, and licensors harmless with respect to any claims arising out of your breach of these Terms, your use of the Website or any of the Services, your non-compliance with applicable laws or regulations in the jurisdiction in which you are accessing the Website or the Services, or any action taken by Krazeal Online “www.krazeal.com” as part of its investigation of a suspected violation of these Terms or as a result of its finding or decision that a violation of these Terms has occurred. You agree to defend, indemnify and hold harmless  Krazeal Online “www.krazeal.com” and its affiliates and group companies and from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related any breach by you of these Terms or any other terms and conditions on the Website.
*UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE, SHALL KRAZEAL ONLINE “WWW.KRAZEAL.COM” OR ANY OF ITS AFFILIATES, GROUP COMPANIES, EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, VENDORS OR SUPPLIERS BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, BUSINESS INTERRUPTION, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF AN AUTHORISED REPRESENTATIVE OF  KRAZEAL ONLINE “WWW.KRAZEAL.COM” HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH (I) THE USE OF OR INABILITY TO USE THE WEBSITE; (II) YOUR USE OF OR RELIANCE ON THE WEBSITE, ANY INFORMATION, HYPERLINKS OR CONTENT CONTAINED THEREIN, OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS WEBSITE; (III) YOUR PROVISION OF INFORMATION, PERSONAL OR OTHERWISE, TO KRAZEAL ONLINE “WWW.KRAZEAL.COM”; (IV) THE PROVISION OF SERVICES BY  KRAZEAL ONLINE “WWW.KRAZEAL.COM” OR; OR (V) ACTS OR NEGLIGENCE ON THE PART OF  KRAZEAL ONLINE “WWW.KRAZEAL.COM”. IN NO EVENT WILL  KRAZEAL ONLINE “WWW.KRAZEAL.COM” BE LIABLE FOR ANY LOSSES, DAMAGES OR EXPENSES IN EXCESS OF THE AMOUNT PAID BY YOU TOWARDS THE PRICE OF THE PRODUCT IN RESPECT TO WHICH THE CLAIM AROSE. YOU HEREBY RELEASE US FROM ANY AND ALL OBLIGATIONS, LIABILITIES AND CLAIMS IN EXCESS OF THIS LIMITATION.

#21.	GENERAL

*If any provision of these Terms and any other terms and condition is held to be unenforceable for any reason, such provision shall be reformed only to the extent necessary to make it enforceable and the other terms of these Terms and any other terms and condition shall remain in full force and effect. The failure of Krazeal Online “www.krazeal.com” to act with respect to a breach of these Terms by you or others does not constitute a waiver and shall not limit Krazeal Online “www.krazeal.com” rights with respect to such breach or any subsequent breaches.
*These terms and any other terms and condition shall be governed by the laws of India and any disputes shall be subject to the exclusively jurisdiction of courts at Bengaluru.
*You acknowledge and agree that no joint venture, partnership, employment, or agency relationship exists between you Krazeal Online “www.krazeal.com” and user as a result of your use of this Website.
*A reference to the Website in these Terms includes the website URL  www.krazeal.com and any successor website URLs.
*These Terms (i) constitute the entire agreement between you and  Kazeal Online “www.krazeal.com” with respect to the subject matter hereof and supersedes any previous oral or written arrangements or understandings relating thereto, (ii) (iv) may not be assigned or resold, in whole or in part, directly or indirectly, by operation of law or otherwise, by you.
*Krazeal Online “www.krazeal.com” shall be not held liable for any delay or failure in performance of any part of these Terms from any cause beyond our control and without our fault or negligence, such as acts of God, acts of civil or military authority, current laws and regulations and changes thereto, embargoes, epidemics, war, terrorist acts, riots, insurrections, fires, explosions, earthquakes, nuclear accidents, floods, strikes, power blackouts, volcanic action, political unrest, other major environmental disturbances, unusually severe weather conditions, acts of hackers and other illegal activities of third parties, inability to secure products or Services of other persons or transportation facilities, or acts or omissions of transportation or telecommunications common carriers or acts, omissions, overloading or slowdowns over the Internet or any third party Internet service providers.

#CUSTOMER CARE

Krazeal Online www.krazeal.com
Dealsmith India Private Limited
Edifice Building No P3 & P4 First Floor
Jeevan Bhima Nagar Main Road
Bangalore - 560075, Karnataka, India
Phone:
Email:
Time: Mon – Sat (9:00 - 17:00)
Thank you for your cooperation. We hope you find this Website helpful and convenient to use.

`

    var policy_text = DbToHtmlFormatting(raw_text);
    setPolicy( policy_text);
  }

  return (
    <>
        <div className="container-fluid-lg">
        <div className="col-sm-12">
        {policy}
        </div>
        </div>
    </>
  )

}
export default PrivacyPolicy
