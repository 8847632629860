// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.search-display {
ul{
    list-style: none;
    display: inline-block;
    width: 420px;
    text-align: left;
}

ul li{
    display: block;
    padding: 15px 20px;
    background-color: #F8F8F8;
    color: #7B8585;
    margin-bottom: 3px;
    position: relative;
    
    transition: 0.3s;
}

ul li a{
    position: absolute;
    left: 160px;
    font-size: 12px;
    line-height: 16px;
    color: #969d9d;
}

ul li:hover{
    background-color:#d8f2f1;
}
input{
    border-radius: 5px 0px 0px 5px;
}
}
`, "",{"version":3,"sources":["webpack://./src/component/search/autocompleteSearch.css"],"names":[],"mappings":";AACA;AACA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;;IAElB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,8BAA8B;AAClC;AACA","sourcesContent":["\n.search-display {\nul{\n    list-style: none;\n    display: inline-block;\n    width: 420px;\n    text-align: left;\n}\n\nul li{\n    display: block;\n    padding: 15px 20px;\n    background-color: #F8F8F8;\n    color: #7B8585;\n    margin-bottom: 3px;\n    position: relative;\n    \n    transition: 0.3s;\n}\n\nul li a{\n    position: absolute;\n    left: 160px;\n    font-size: 12px;\n    line-height: 16px;\n    color: #969d9d;\n}\n\nul li:hover{\n    background-color:#d8f2f1;\n}\ninput{\n    border-radius: 5px 0px 0px 5px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
