import React from 'react'
// import Header2 from '../common/Header2'
// import Footer2 from '../common/Footer2'

const Faq = () => {
  return (
    <>
     {/* <Header2/> */}
     {/*
      <section className="faq-breadscrumb pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Help Center</h2>
                <p>

                </p>
                <div className="faq-form-tag">
                  <div className="input-group">
                    <div className="dropdown">
                      <ul className="dropdown-menu faq-dropdown-menu dropdown-menu-end">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-contain">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="slider-4-2 product-wrapper slick-initialized slick-slider">
                <div className="slick-list draggable"><div className="slick-track" style={{opacity: 1, width: '956px', transform: 'translate3d(0px, 0px, 0px)'}}><div className="slick-slide slick-current slick-active" data-slick-index={0} aria-hidden="false" tabIndex={0} style={{width: '239px'}}>
                      <div className="faq-top-box">
                        <div className="faq-box-icon">
                          <img src="../assets/images/inner-page/faq/start.png" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="faq-box-contain">
                          <h3>Getting Started</h3>
                          <p>Bring to the table win-win survival strategies to ensure proactive domination.
                          </p>
                        </div>
                      </div>
                    </div><div className="slick-slide slick-active" data-slick-index={1} aria-hidden="false" tabIndex={0} style={{width: '239px'}}>
                      <div className="faq-top-box">
                        <div className="faq-box-icon">
                          <img src="../assets/images/inner-page/faq/help.png" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="faq-box-contain">
                          <h3>Sales Question</h3>
                          <p>Lorizzle ipsizzle boom shackalack sit get down get down.</p>
                        </div>
                      </div>
                    </div><div className="slick-slide slick-active" data-slick-index={2} aria-hidden="false" tabIndex={0} style={{width: '239px'}}>
                      <div className="faq-top-box">
                        <div className="faq-box-icon">
                          <img src="../assets/images/inner-page/faq/price.png" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="faq-box-contain">
                          <h3>Pricing &amp; Plans</h3>
                          <p>Curabitizzle fizzle break yo neck, yall quis fo shizzle mah nizzle fo rizzle.</p>
                        </div>
                      </div>
                    </div><div className="slick-slide slick-active" data-slick-index={3} aria-hidden="false" tabIndex={0} style={{width: '239px'}}>
                      <div className="faq-top-box">
                        <div className="faq-box-icon">
                          <img src="../assets/images/inner-page/faq/contact.png" className="blur-up lazyloaded" alt="" />
                        </div>
                        <div className="faq-box-contain">
                          <h3>Support Contact</h3>
                          <p>Gizzle fo shizzle bow wow wow bizzle leo bibendizzle check out this.</p>
                        </div>
                      </div>
                    </div></div></div>
              </div>
            </div>
          </div>
        </div>
      </section>
     */}

      <section className="faq-box-contain section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-xl-5">
              <div className="faq-contain">
                <h2>Frequently Asked Questions</h2>
                <p>
                Welcome to Krazeal. If you are reading this, you are a new user at Krazeal and that you would like to know more about the site and how to find things around here!
                </p>
                <p>

Krazeal is all about saving a lot of cash. We all love shopping but it becomes more fun when you get deals at rates which make the deal hotter.
                </p>

              </div>
            </div>
            <div className="col-xl-7">
              <div className="faq-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How much cash do I save in each deal?
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Krazeal.com is designed in such a way that buyers get minimum 40% discount from MRP                on most of products.
                          </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        After applying Promo code do we get further discounts?
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Yes after applying promo codes deals will be further discounted. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Where will I get promo codes?
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Promo codes will be published on our homepage banners as well as our social media pages like facebook, Instagram, Twitter etc.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                        Why is there daily cap in certain categories?
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Daily cap is there in certain categories to ensure that our deal should reach maximum number of customers instead of few bulk buyers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Can I transfer the deal or voucher to my best friend?
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Yes you can transfer deal or voucher to your friends or family.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Can I convert my voucher to hard cold cash? What is the value of each voucher?
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" /></svg>
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          If there is deal on any voucher it needs to be redeemed against products only not against cash.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default Faq
